import { useEffect, useState } from 'react'

const useFormatSize = bytes => {
  const [converted, setConverted] = useState('')

  useEffect(() => {
    if (bytes >= 1099511627776) {
      setConverted(`${(bytes / 1099511627776).toFixed(2)} TB`)
    } else if (bytes >= 1073741824) {
      setConverted(`${(bytes / 1073741824).toFixed(2)} GB`)
    } else if (bytes >= 1048576) {
      setConverted(`${(bytes / 1048576).toFixed(2)} MB`)
    } else if (bytes >= 1024) {
      setConverted(`${(bytes / 1024).toFixed(2)} KB`)
    } else {
      setConverted(`${bytes} bytes`)
    }
  }, [bytes])

  return converted
}

export default useFormatSize
