import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import IconButton from 'components/Button/IconButton'
import Container from 'components/Container'
import LoadingLayer from 'components/LoadingLayer'
import ScrollWrapper from 'components/ScrollWrapper'

import { deleteBookmark, useBookmarks } from 'data/bookmarks'

const StyledBookmarkWrapper = styled.div`
  ${tw`flex items-stretch mb-4 transition-all duration-100 rounded-lg bg-white shadow relative divide-x divide-gray-200`}
  ${tw`hover:shadow-lg hover:z-10`}
`

const StyledLink = styled(Link)`
  ${tw`py-2 px-4 rounded-tr-lg rounded-br-lg transition-all duration-100`}
`

const StyledIconButton = styled(IconButton)`
  ${tw`py-2 px-4 active:outline-none focus:outline-none rounded-tl-lg rounded-bl-lg hover:bg-transparent`}
`

const StyledPath = styled.span`
  ${tw`text-gray-400 text-sm`}
`

const StyledTitle = styled.span`
  ${tw`mr-2 text-lg block`}
`

export const getBookmarkTitle = bookmark => {
  if (!bookmark) return
  let title = ''

  switch (bookmark.resource.__type) {
    case 'Category':
      title = bookmark.resource?.name
      break
    case 'Process':
      title = bookmark.resource?.name
      break
    case 'Step':
      title = bookmark.resource?.title
      break
    default:
      title = ''
      break
  }

  return title
}

export const getBookmarkUrl = bookmark => {
  if (!bookmark) return
  let url = ''

  switch (bookmark.resource.__type) {
    case 'Category':
      url = `/categories/${bookmark.resource?.id}`
      break
    case 'Process':
      url = `/categories/${bookmark.resource?.category?.id}/processes/${bookmark.resource?.id}`
      break
    case 'Step':
      url = `/categories/${bookmark.resource?.category?.id}/processes/${bookmark.resource?.process?.id}/${bookmark.resource?.id}`
      break
    default:
      url = ''
      break
  }

  return url
}

export const getBookmarkPath = bookmark => {
  if (!bookmark) return
  let path = ''

  switch (bookmark.resource.__type) {
    case 'Category':
      path = `Themen und Kategorien / ${bookmark.resource.topic.name}`
      break
    case 'Process':
      path = `Themen und Kategorien / ${bookmark.resource.topic.name} / ${bookmark.resource.category.name}`
      break
    case 'Step':
      path = `Themen und Kategorien / ${bookmark.resource.topic.name} / ${bookmark.resource.category.name} / ${bookmark.resource.process.name}`
      break
    default:
      path = ''
      break
  }

  return path
}

const BookmarkScene = () => {
  const {
    bookmarks,
    isLoading: isBookmarksLoading,
    isError: isBookmarksError,
    mutate: mutateBookmarks,
  } = useBookmarks()

  const performDeleteBookmark = async id => {
    await deleteBookmark(id)
    await mutateBookmarks()
  }

  if (isBookmarksLoading || isBookmarksError)
    return (
      <LoadingLayer isError={isBookmarksError} isLoading={isBookmarksLoading} />
    )

  return (
    <ScrollWrapper>
      <Container>
        <h1>Lesezeichen</h1>
        <div>
          {bookmarks && bookmarks.length ? (
            bookmarks.map((bookmark, key) => {
              if (!bookmark) return null

              return (
                <StyledBookmarkWrapper key={key}>
                  <StyledIconButton
                    className="icon-fill"
                    icon="bookmark"
                    onClick={e => {
                      e.stopPropagation()
                      performDeleteBookmark(bookmark.id)
                    }}
                  />
                  <StyledLink
                    className="flex-1 truncate"
                    to={getBookmarkUrl(bookmark)}
                  >
                    <StyledPath>{getBookmarkPath(bookmark)}</StyledPath>
                    <StyledTitle>{getBookmarkTitle(bookmark)}</StyledTitle>
                  </StyledLink>
                </StyledBookmarkWrapper>
              )
            })
          ) : (
            <div className="italic">Keine Lesezeichen vorhanden.</div>
          )}
        </div>
      </Container>
    </ScrollWrapper>
  )
}

export default BookmarkScene
