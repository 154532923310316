import React from 'react'

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

export const ConfirmActionModal = ({
  title,
  explanation,
  onConfirm,
  isLoading,
  isOpen,
  onClose,
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="yellow.400" color="black">
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>{explanation}</Box>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" w="100%">
              <Button variant="unstyled" color="black" onClick={onClose}>
                Abbrechen
              </Button>
              <Button
                variant="solid"
                bg="white"
                color="black"
                isLoading={isLoading}
                onClick={onConfirm}
              >
                Fortfahren
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
