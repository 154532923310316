import flattenDeep from 'lodash/flattenDeep'
import union from 'lodash/union'
import StepNormalizer from './StepNormalizer'

class ProcessNormalizer {
  constructor(process) {
    this.process = process ?? {}
    this.steps = (process && process.steps) ?? []
  }

  getRootStep() {
    return this.steps && this.steps.find(step => step.previous_step_id === null)
  }

  // Reference: https://stackoverflow.com/questions/32127721/lodash-to-flatten-json-and-show-original-path
  flattenSteps(steps) {
    const recurse = (nodes, path) => {
      return nodes.map(node => {
        const newPath = union(path, [node.title])

        return [
          Object.assign(
            { pathname: newPath.join(' > '), level: path.length },
            node
          ),
          recurse(node.children_sorted, newPath),
        ]
      })
    }
    return flattenDeep(recurse(steps, []))
  }

  get transformedProcess() {
    const isUnpublishedProcess = !this.process.published_at

    if (this.process.steps) {
      this.process.steps = this.process.steps.map(step =>
        StepNormalizer.normalize(step)
      )
    }

    // this needs to stay the same object so that comparisons of react work
    return Object.assign(this.process, {
      steps_flat: this.process.steps && this.flattenSteps(this.process.steps),
      name: isUnpublishedProcess
        ? this.process.modified_name || this.process.name
        : this.process.name,
    })
  }

  static normalize(process) {
    const instance = new ProcessNormalizer(process)

    return instance.transformedProcess
  }
}

export default ProcessNormalizer
