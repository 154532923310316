import Tippy from '@tippyjs/react'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import { NotificationContext } from 'context/notification'

import IconLoader from 'components/IconLoader'
import LogoutButton from './Auth/LogoutButton'

const Icon = styled(IconLoader)`
  ${tw`block w-6 h-6`}
`

const NavWrapper = styled.div`
  ${tw`flex h-full shadow-xs fixed top-0 left-0 bottom-0 z-50`}
`

const TabBar = styled.nav`
  ${tw`z-50 flex flex-wrap items-start w-full h-full bg-gray-900 shadow`}
`

const TabWrapper = styled.div`
  ${tw`flex flex-col items-center justify-between h-full p-2 overflow-auto`}
`

const StyledTab = styled(NavLink)`
  ${tw`flex flex-col items-center flex-1 relative p-3 mb-1 text-xs text-center text-gray-500 no-underline rounded`}
  transition: all 0.33s ease;

  &:hover {
    ${tw`text-white`}
  }

  &.active {
    ${tw`text-white bg-gray-700`}
  }
`

const StyledButton = styled.button`
  ${tw`flex flex-col items-center flex-1 relative p-3 text-xs text-center text-gray-500 no-underline outline-none rounded`}
  transition: all 0.33s ease;

  &:hover {
    ${tw`text-white`}
  }

  &:focus {
    ${tw`outline-none`}
  }

  &:active {
    ${tw`outline-none`}
  }

  &.active {
    ${tw`text-white bg-gray-700`}
  }
`

const NotificationBadge = styled.span`
  ${tw`absolute top-0 right-0 rounded-full h-4 w-4`};
  background-color: #00adef;
`

const Tab = ({ to, exact, icon = '', badge = false, ...rest }) => (
  <StyledTab to={to} exact={exact} {...rest}>
    <Icon name={icon} />
    {!!badge && <NotificationBadge />}
  </StyledTab>
)

const UserTabBar = styled.div`
  ${tw`flex flex-col`}

  & a,
  & button {
    ${tw`flex items-center px-2 py-1 text-gray-500`}

    &:not(:last-child) {
      ${tw`mb-1`}
    }

    &:hover {
      ${tw`bg-gray-200`}
    }

    &:focus {
      ${tw`focus:outline-none`}
    }

    &.active {
      ${tw`bg-gray-200`}
    }

    & svg {
      ${tw`mr-1 w-6 h-6`}
    }
  }
`

const StyledButtonGroup = styled.div``

const TabNavigation = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleNav = () => setIsOpen(!isOpen)

  return (
    <NavWrapper>
      <TabBar>
        <TabWrapper>
          <StyledButtonGroup>
            <Tab exact to="/" icon="home" title="Home" />
            <Tab to="/categories" icon="grid" title="Themen undKategorien" />
            <Tab to="/search" icon="search" title="Suche" />
            <Tab to="/bookmarks" icon="bookmark" title="Lesezeichen" />
            <Tab to="/media-library/all-videos" icon="play" title="Videos" />
            <Tab to="/faq" icon="help-circle" title="Fragen und Antworten" />
          </StyledButtonGroup>
          <StyledButtonGroup>
            <NotificationContext.Consumer>
              {({ count, refreshCount }) => (
                <Tab
                  to="/notifications"
                  icon="message-circle"
                  title="Neuigkeiten"
                  badge={count > 0}
                  onClick={refreshCount}
                />
              )}
            </NotificationContext.Consumer>
            <Tippy
              trigger="click"
              placement="right"
              offset={[0, 15]}
              interactive="true"
              theme="guido"
              onShow={toggleNav}
              onHide={toggleNav}
              content={
                <UserTabBar>
                  <LogoutButton />
                </UserTabBar>
              }
            >
              <StyledButton
                onClick={e => {
                  e.preventDefault()
                }}
                className={isOpen && 'active'}
              >
                <Icon name="user" />
              </StyledButton>
            </Tippy>
          </StyledButtonGroup>
        </TabWrapper>
      </TabBar>
    </NavWrapper>
  )
}

export default TabNavigation
