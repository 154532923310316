import DashboardBox from 'components/Dashboard/DashboardBox'
import React from 'react'
import { LifeBuoy, Mail, Phone } from 'react-feather'
import styles from './index.module.css'

const Component = ({ box }) => {
  const options = JSON.parse(box?.options || '{}')

  return (
    <DashboardBox>
      <DashboardBox.Header
        icon={<LifeBuoy size={24} />}
        title={options?.title || 'Support'}
      />
      <DashboardBox.Content>
        {options.description && (
          <div className={styles.supportText}>
            <p dangerouslySetInnerHTML={{ __html: options.description.replace(/\n/g, '<br>') }}></p>
          </div>
        )}

        {options.email && (
          <div className={styles.supportContactWrapper}>
            <Mail size={20} className="mr-4" />
            <a
              type="email"
              href={`mailto:${options.email}`}
              className={styles.supportContact}
            >
              {options.email}
            </a>
          </div>
        )}

        {options.phone && (
          <div className={`${styles.supportContactWrapper} mt-2`}>
            <Phone size={20} className="mr-4" />
            <a
              type="phone"
              href={`tel:${options.phone}`}
              className={styles.supportContact}
            >
              {options.phone}
            </a>
          </div>
        )}
      </DashboardBox.Content>
    </DashboardBox>
  )
}

export default Component
