import useSWR from 'swr'
import MeNormalizer from './utils/MeNormalizer'
import { useMemo } from 'react'

export const useMe = () => {
  const { data, mutate, error } = useSWR(`/me`)

  const normalizedMe = useMemo(() => {
    return data && MeNormalizer.normalize(data)
  }, [data])

  return {
    me: normalizedMe,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}

export default useMe
