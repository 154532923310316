import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import IconLoader from 'components/IconLoader'
import BaseButton from './BaseButton'

const Icon = styled(IconLoader)`
  ${tw`w-6 h-6`}
  ${props => (props.size === 'large' ? tw`w-8 h-8` : null)};
`

const StyledBaseButton = styled(BaseButton)`
  ${tw`flex items-center`}
`

const IconButton = ({ className, icon, label, onClick, size, ...rest }) => (
  <StyledBaseButton className={className} onClick={onClick} {...rest}>
    <Icon name={icon} size={size} /> {label}
  </StyledBaseButton>
)

export default IconButton
