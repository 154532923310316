import useSWR from 'swr'
import axios from 'axios'

export const useBookmarks = () => {
  const { data, error, mutate } = useSWR(`/bookmarks`)

  return {
    bookmarks: data && data.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}

/** @DEPRECATED */
export const setBookmark = (type, id) => {
  console.warn('This function is deprecated.')
  return axios.post('/bookmarks', {
    data: {
      bookmark_type: type,
      bookmark_id: id,
    },
  })
}

export const createBookmark = (type, id) => {
  return axios.post('/bookmarks', {
    data: {
      bookmark_type: type,
      bookmark_id: id,
    },
  })
}

/** @DEPRECATED */
export const unsetBookmark = id => {
  console.warn('This function is deprecated.')
  return axios.delete(`/bookmarks/${id}`)
}

export const deleteBookmark = id => {
  return axios.delete(`/bookmarks/${id}`)
}
