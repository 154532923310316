import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import IconButton from 'components/Button/IconButton'

import { useBackButton } from 'context/back-button'

const StyledBackButton = styled(IconButton)`
  ${tw`flex items-center p-2 active:outline-none focus:outline-none rounded`}
  ${tw`text-gray-500`}
`

const BackButton = ({ className, label, history, ...rest }) => {
  const { backTo } = useBackButton()

  return (
    <StyledBackButton
      className={className}
      icon="chevron-left"
      onClick={e => {
        if (!backTo) return history.goBack()

        sessionStorage.setItem('userHasClickedBackButton', true)

        return history.push(backTo)
      }}
      {...rest}
    />
  )
}

export default withRouter(BackButton)
