import React, { useEffect } from 'react'
import ProgressiveImage from 'react-progressive-image'
import styled from 'styled-components'
import tw from 'twin.macro'

import ImgPlaceholder from 'assets/empty-process.png'
import Container from 'components/Container'
import LoadingLayer from 'components/LoadingLayer'
import ProcessFrame from 'components/Process/ProcessFrame'
import UnpublishedIcon from 'components/UnpublishedIcon'
import { nl2br } from 'utils/nl2br'
import { useGraphic } from 'data/graphic'

const { REACT_APP_API_URL: API_URL } = process.env

const StyleProcessGraphicWrapper = styled.div`
  ${tw`relative border border-gray-100 flex`}
`

const StyledProcessGraphicFrames = styled.div`
  ${tw`absolute top-0 bottom-0 left-0 right-0 w-full h-full`}
`

const ProcessDescription = styled.p`
  ${tw`mb-8`}
`

const ShowProcessGraphic = ({ process, setStepInBreadcrumb }) => {
  const {
    graphic,
    isLoading: isGraphicLoading,
    isError: isGraphicError,
  } = useGraphic(process.graphic_id)

  // Reset Breadcrumb
  useEffect(() => {
    setStepInBreadcrumb(null)
  }, [setStepInBreadcrumb])

  const titleJsx = (
    <h1 className="flex items-start w-full">
      <UnpublishedIcon className="mt-4" entity={process} />
      {process.name}
    </h1>
  )

  const descriptionJsx = process.description ? (
    <ProcessDescription>{nl2br(process.description)}</ProcessDescription>
  ) : null

  if (!process.graphic_id) {
    return (
      <Container>
        {titleJsx}
        {descriptionJsx || (
          <div className="p-8 flex justify-center items-center min-h-full bg-white text-center">
            <div>
              <img
                className="inline-block mb-8 w-60"
                src={ImgPlaceholder}
                alt="Dieser Prozess enthält noch keine Grafik."
              />
              <span className="block">
                Dieser Prozess enthält noch keine Grafik.
              </span>
            </div>
          </div>
        )}
      </Container>
    )
  }

  if (isGraphicError || isGraphicLoading) {
    return (
      <LoadingLayer
        className="m-h-full absolute top-0 right-0 bottom-0 left-0 w-full"
        isError={isGraphicError}
        isLoading={isGraphicLoading}
      />
    )
  }

  const frames = graphic.frames

  // Return if graphic has no media property
  if (!graphic.media || !graphic?.media.image_formats) return null

  // Find tiny format and save as object in tinyImage
  let tinyImage = graphic.media.image_formats.find(
    format => format.type === 'tiny'
  )
  // Fallback for tinyImage
  tinyImage = tinyImage ? tinyImage : graphic.media

  // Find large format and save as object in largeImage
  let largeImage = graphic.media.image_formats.find(
    format => format.type === 'large'
  )
  // Fallback for largeImage
  largeImage = largeImage ? largeImage : graphic.media

  return (
    <Container>
      {titleJsx}
      {descriptionJsx}
      <StyleProcessGraphicWrapper>
        {process.graphic_id ? (
          <div className="relative m-auto w-auto inline-block">
            <ProgressiveImage
              src={`${API_URL}${largeImage.media_url}`}
              placeholder={`${API_URL}${tinyImage.media_url}`}
            >
              {(src, loading) => (
                <img
                  className="mw-full transition-all duration-200"
                  style={{
                    filter: loading ? 'blur(10px)' : 'blur(0)',
                    opacity: loading ? 0.5 : 1,
                  }}
                  src={src}
                  alt="Prozessschaubild"
                />
              )}
            </ProgressiveImage>

            <StyledProcessGraphicFrames>
              {frames &&
                frames.map(frame => (
                  <ProcessFrame key={frame.id} frame={frame} />
                ))}
            </StyledProcessGraphicFrames>
          </div>
        ) : (
          <div>Keine Grafik</div>
        )}
      </StyleProcessGraphicWrapper>
    </Container>
  )
}

export default ShowProcessGraphic
