import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const StyledContainer = styled.div`
  ${({ centered }) => centered && tw`mx-auto`}
`

const Container = ({ children, className = '', centered = true }) => (
  <StyledContainer
    className={`w-full max-w-6xl p-8 ${className}`}
    centered={centered}
  >
    {children}
  </StyledContainer>
)

export default Container
