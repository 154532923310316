import React from 'react'

import { useAuth } from 'context/auth'

import { IconButton } from 'components/Button'

const LogoutButton = ({ className, label = 'Ausloggen' }) => {
  const { logout } = useAuth()

  return (
    <IconButton
      className={className}
      icon="logout"
      onClick={() => logout()}
      label={label}
    />
  )
}

export default LogoutButton
