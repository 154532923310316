import Tippy from '@tippyjs/react'
import React from 'react'

import Icon from 'components/IconLoader'

const UnpublishedIcon = ({ className = '', entity, size = 5 }) => {
  const content =
    entity.__type === 'Category'
      ? 'Kategorie nicht veröffentlicht'
      : entity.__type === 'Process'
      ? 'Prozess nicht veröffentlicht'
      : entity.__type === 'Step'
      ? 'Schritt nicht veröffentlicht'
      : 'Nicht veröffentlicht'

  return (
    !entity.published_at && (
      <Tippy content={content}>
        <Icon
          className={`${className} w-${size} h-${size} text-gray-500 flex-shrink-0 mr-2`}
          name="eye-off"
        />
      </Tippy>
    )
  )
}

export default UnpublishedIcon
