import React from 'react'
import { NavLink } from 'react-router-dom'

import { Box, Flex, Text } from '@chakra-ui/react'

const getType = item => item.__type

const getTitle = item => {
  return item.name || item.title
}

const getOldTitleIfAvailable = item => {
  return item.old_name || item.old_title
}

const getUrl = item => {
  switch (getType(item)) {
    case 'Category':
      return `/categories/${item.id}`
    case 'Process':
      return `/categories/${item.category.id}/processes/${item.id}`
    case 'Step':
      return `/categories/${item.category.id}/processes/${item.process.id}/${item.id}`
    default:
      return null
  }
}

const getPath = item => {
  switch (getType(item)) {
    case 'Category':
      return `Themen und Kategorien / ${item.topic.name} / ${item.name}`
    case 'Process':
      return `Themen und Kategorien / ${item.topic.name} / ${item.category.name} / ${item.name}`
    case 'Step':
      return `Themen und Kategorien / ${item.topic.name} / ${item.category.name} / ${item.process.name} / ${item.title}`
    default:
      return null
  }
}

const _getSearchTermTitle = (str, searchTerm) => {
  if (!str) return

  const regex = new RegExp('(' + searchTerm + ')', 'gi')
  let stringValues = str.split(regex)
  stringValues = stringValues ? stringValues.filter(s => s !== '') : []
  return stringValues
}

const _getSearchTermContent = (content, searchTerm) => {
  if (!content) return

  const regex = new RegExp('(' + searchTerm + ')', 'gi')

  const contentString = content
    .map(item =>
      item.children.map(child => child.text ?? '').map(item => item + ' ')
    )
    .join('')

  let contentStringValues = contentString.split(regex)
  contentStringValues = contentStringValues
    ? contentStringValues.filter(s => s !== '')
    : []

  return contentStringValues
}

const renderOldTitle = (item, searchTerm) => {
  const oldtitle = getOldTitleIfAvailable(item)

  return (
    oldtitle &&
    _getSearchTermTitle(oldtitle, searchTerm) &&
    _getSearchTermTitle(oldtitle, searchTerm).map((item, index) => (
      <span key={index}>
        {new RegExp(searchTerm, 'gi').test(item) ? (
          <span className="text-blue-500">{item}</span>
        ) : (
          <span>{item}</span>
        )}
      </span>
    ))
  )
}

const renderTitle = (item, searchTerm) => {
  const title = getTitle(item)

  return (
    _getSearchTermTitle(title, searchTerm) &&
    _getSearchTermTitle(title, searchTerm).map((item, index) => (
      <span key={index}>
        {new RegExp(searchTerm, 'gi').test(item) ? (
          <span className="text-blue-500">{item}</span>
        ) : (
          <span>{item}</span>
        )}
      </span>
    ))
  )
}

const renderContent = (content, searchTerm) => {
  return (
    _getSearchTermContent(content, searchTerm) &&
    _getSearchTermContent(content, searchTerm).map((item, index) => (
      <span key={index}>
        {new RegExp(searchTerm, 'gi').test(item) ? (
          <span className="text-blue-500">{item}</span>
        ) : (
          <span>{item}</span>
        )}
      </span>
    ))
  )
}

const SearchResultItem = ({ item, searchTerm }) => {
  return (
    <Box
      as={NavLink}
      to={getUrl(item)}
      display="block"
      p={4}
      mb={3}
      rounded="lg"
      shadow="base"
      bg="white"
      isTruncated
    >
      <Flex align="start" justify="space-between">
        <Box flex={1} w="full">
          <Text
            as="div"
            fontSize="sm"
            mt={0}
            mb={1}
            textColor="gray.400"
            isTruncated
          >
            {getPath(item)}
          </Text>
          <Box>
            <Text flex={1} as="div" fontSize="lg" isTruncated>
              {renderTitle(item, searchTerm)}
              {getOldTitleIfAvailable(item) ? (
                <Text ml={4} as="span" fontSize="sm" isTruncated>
                  ({renderOldTitle(item, searchTerm)})
                </Text>
              ) : null}
            </Text>
            {getType(item) === 'Step' && (
              <Text as="div" fontSize="sm" isTruncated>
                {renderContent(item.content, searchTerm)}
              </Text>
            )}
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default SearchResultItem
