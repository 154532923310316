import React from 'react'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react'

import { useFAQs } from 'data/faq'

import Container from 'components/Container'
import LoadingLayer from 'components/LoadingLayer'
import ScrollWrapper from 'components/ScrollWrapper'

const FAQScene = () => {
  const { faqs, isLoading, isError } = useFAQs()

  if (isLoading || isError)
    return <LoadingLayer isError={isError} isLoading={isLoading} />

  return (
    <>
      <ScrollWrapper>
        <Container>
          <h1>Fragen und Antworten</h1>
          <Accordion w="100%" allowMultiple={false} allowToggle={true}>
            {faqs &&
              faqs.map((faq, index) => (
                <AccordionItem
                  //alignItems="center"
                  py={4}
                  w="100%"
                  key={index}
                  bg="white"
                  borderWidth={0}
                  mb={4}
                  rounded="lg"
                  shadow="base"
                >
                  <AccordionButton
                    _hover={{ bg: 'white' }}
                    alignItems="start"
                    fontSize="lg"
                    fontWeight="normal"
                    _focus={{ outline: 'none' }}
                    py={0}
                  >
                    <Box w={8} mr={4} color="gray.400">
                      {index + 1}.
                    </Box>
                    <Box flex="1" textAlign="start" lineHeight="normal">
                      {faq.question}
                    </Box>
                    <AccordionIcon ml={2} color="gray.400" />
                  </AccordionButton>
                  <AccordionPanel pb={2} pt={4}>
                    <Box
                      whiteSpace="pre-wrap"
                      ml={12}
                      mr={10}
                      flex="1"
                      textAlign="start"
                      color="gray.600"
                    >
                      {faq.answer}
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              ))}
          </Accordion>
        </Container>
      </ScrollWrapper>
    </>
  )
}

export default FAQScene
