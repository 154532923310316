/**
 * Route: /categories
 */

import React, { useEffect } from 'react'

import CategoryGrid from 'components/Category/CategoryGrid'
import LoadingLayer from 'components/LoadingLayer'
import { useCategories } from 'data/categories'

import { useBackButton } from 'context/back-button'

const ShowCategories = () => {
  const { categories, isLoading, isError } = useCategories()
  const { setBackTo } = useBackButton()

  useEffect(() => {
    setBackTo('/')
  }, [])

  if (isLoading || isError)
    return <LoadingLayer isError={isError} isLoading={isLoading} />

  return <CategoryGrid categories={categories} />
}

export default ShowCategories
