export const useDate = (plainDate, withTime = true) => {
  if (!plainDate) return

  const date =
    plainDate instanceof Date ||
    Object.prototype.toString.call(plainDate) === '[object Date]'
      ? plainDate
      : new Date(plainDate)
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // January is 0!
  const year = date.getFullYear()

  let time = ''
  if (withTime) {
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')

    time = ` ${hours}:${minutes}`
  }

  return `${day}.${month}.${year}${time}`
}
