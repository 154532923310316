import DashboardBox from 'components/Dashboard/DashboardBox'
import { useNotifications } from 'data/notifications'
import React, { useEffect, useState } from 'react'
import { ArrowRight, ChevronRight, MessageCircle } from 'react-feather'
import { Link } from 'react-router-dom'
import theme from 'theme'

import styles from './index.module.css'
import {
  getNotificationPath,
  getNotificationTitle,
  getNotificationUrl,
} from 'scenes/Notifications/ShowNotifications'

const Component = ({ box }) => {
  let options = JSON.parse(box?.options || '{}')

  const { notifications, isLoading } = useNotifications()

  const [unreadNotifications, setUnreadNotifications] = useState([])

  useEffect(() => {
    if (notifications) {
      setUnreadNotifications(notifications.filter(n => n.read === false))
    }
  }, [notifications])

  return (
    <DashboardBox isLoading={isLoading}>
      <DashboardBox.Header
        icon={<MessageCircle size={24} />}
        title={options?.title || 'Benachrichtigungen'}
      />
      <DashboardBox.Content
        noContentText="Es sind keine ungelesenen Benachrichtigungen vorhanden."
        hasNoContent={!unreadNotifications || unreadNotifications?.length < 1}
      >
        <div>
          <ul className="list-none">
            {unreadNotifications &&
              unreadNotifications
                .slice(0, options.max || 3)
                .map((notification, key) => {
                  return (
                    <div className={styles.wrapper} key={key}>
                      <MessageCircle
                        className={`${styles.icon} icon-fill icon-stroke`}
                        style={{ color: theme.default.colors.primary }}
                        size={27}
                      />
                      <Link
                        className={styles.link}
                        to={getNotificationUrl(notification)}
                      >
                        <div className={styles.textWrapper}>
                          <div className={styles.path}>
                            {getNotificationPath(notification)}
                          </div>
                          <div className={styles.title}>
                            {getNotificationTitle(notification)}
                          </div>
                          <div className={styles.text}>
                            {notification?.text}
                          </div>
                        </div>
                        <ChevronRight size={24} className={styles.iconGray} />
                      </Link>
                    </div>
                  )
                })}
          </ul>
        </div>
      </DashboardBox.Content>
      <DashboardBox.Footer>
        <Link className={styles.link} to={`/notifications`}>
          <DashboardBox.Footer.Button>
            Alle anzeigen
            <ArrowRight className="ml-2" size={20} stroke="#64748B" />
          </DashboardBox.Footer.Button>
        </Link>
      </DashboardBox.Footer>
    </DashboardBox>
  )
}

export default Component
