import { createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'

const GlobalStyle = createGlobalStyle`
  html {
    ${tw`h-full`}
  }

  body {
    ${tw`font-sans m-0 p-0 w-full h-full`}
    ${props => (props.whiteColor ? tw`text-white` : tw`text-gray-900`)};
  }

  #root {
    ${tw`w-full h-full`}
  }

  *,
  *:before,
  *:after {
    ${tw`box-border`}
  }
`

export default GlobalStyle
