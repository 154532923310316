import React, { useState } from 'react'
import { Prompt, Route, Switch, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import BookmarkScene from 'scenes/Bookmarks'
import ShowQuestions from 'scenes/Categories/Process/eLearning/ShowQuestions'
import ShowProcess from 'scenes/Categories/Process/ShowProcess'
import ShowCategories from 'scenes/Categories/ShowCategories'
import ShowCategory from 'scenes/Categories/ShowCategory'
import FAQScene from 'scenes/FAQ'
import HomeScene from 'scenes/Home'
import MediaOverviewScene from 'scenes/MediaLibrary/MediaOverview'
import SearchScene from 'scenes/Search'
import SettingsScene from 'scenes/Settings'

import Modal from 'components/Modal'
import TabNavigation from 'components/TabNavigation'
import ShowNotifications from 'scenes/Notifications/ShowNotifications'
import useScrollRestoration from 'hooks/use-scroll-restoration'

const AppContainer = styled.div`
  ${tw`flex flex-row w-screen min-h-screen bg-gray-50`}
`

const AppView = styled.div`
  ${tw`min-h-full w-full ml-16 overflow-x-auto`}
`

const AppWrapper = () => {
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => setShowModal(!showModal)

  const currentLocation = useLocation()

  useScrollRestoration()

  return (
    <AppContainer>
      <Prompt
        when={true}
        message={() => {
          sessionStorage.setItem(
            currentLocation.pathname,
            JSON.stringify(window.scrollY)
          )
        }}
      />

      <TabNavigation showModal={showModal} toggleModal={toggleModal} />
      <AppView>
        {showModal ? <Modal toggleModal={toggleModal} /> : null}
        <Switch>
          <Route exact path="/" component={HomeScene} />
          <Route exact path="/categories" component={ShowCategories} />
          <Route exact path="/categories/:cid" component={ShowCategory} />
          <Route
            exact
            path="/categories/:cid/processes/:pid/questions"
            component={ShowQuestions}
          />
          <Route
            path="/categories/:cid/processes/:pid"
            component={ShowProcess}
          />
          <Route path="/search" component={SearchScene} />
          <Route path="/bookmarks" component={BookmarkScene} />
          <Route path="/media-library" component={MediaOverviewScene} />
          <Route path="/faq" component={FAQScene} />
          <Route path="/settings" component={SettingsScene} />
          <Route path="/notifications" component={ShowNotifications} />
        </Switch>
      </AppView>
    </AppContainer>
  )
}

export default AppWrapper
