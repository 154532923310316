import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'

import styled from 'styled-components'
import tw from 'twin.macro'

import LoadingLayer from 'components/LoadingLayer'
import OffCanvasNav from 'components/OffCanvasNav'
import ScrollWrapper from 'components/ScrollWrapper'

import ShowAllVideosScene from 'scenes/MediaLibrary/ShowAllVideos'
import ShowCategoryVideosScene from 'scenes/MediaLibrary/ShowCategoryVideos'

import { useCategories } from 'data/categories'
import { useVideos } from 'data/videos'

const StyledMediaOverviewWrapper = styled.div`
  ${tw`flex flex-col min-h-full bg-white`}
`

const StyledScrollWrapper = styled(ScrollWrapper)`
  ${tw`relative w-full h-full bg-white`}

  transition: margin-left 0.5s;
`

const MediaOverviewScene = () => {
  const [showNavigation, setShowNavigation] = useState(() => {
    if (parseInt(localStorage.getItem('guido-media-nav-open')) === 1) {
      return true
    } else if (
      parseInt(localStorage.getItem('guido-media-nav-open')) === null
    ) {
      return true
    } else if (parseInt(localStorage.getItem('guido-media-nav-open')) === 0) {
      return false
    } else {
      return true
    }
  })

  const naviWidth = '18rem'

  const openNavigation = () => {
    localStorage.setItem('guido-media-nav-open', 1)
    setShowNavigation(true)
  }
  const closeNavigation = () => {
    localStorage.setItem('guido-media-nav-open', 0)
    setShowNavigation(false)
  }

  const { categories, isError, isLoading } = useCategories()
  const {
    videos: initialVideos,
    isInitialVideosLoading,
    isInitialVideosError,
  } = useVideos(1, 1)

  const { videos, isVideosLoading, isVideosError } = useVideos(
    1,
    initialVideos && initialVideos.total
  )

  if (
    isLoading ||
    isError ||
    isInitialVideosError ||
    isInitialVideosLoading ||
    isVideosError ||
    isVideosLoading
  ) {
    return (
      <LoadingLayer
        isError={isError || isInitialVideosError || isVideosError}
        isLoading={isLoading || isInitialVideosLoading || isVideosLoading}
      />
    )
  }

  const filteredCategories =
    videos &&
    videos.data &&
    categories.filter(
      category =>
        category &&
        videos.data.find(
          video =>
            video &&
            video.category &&
            video.category.id &&
            video.category.id === category.id
        )
    )

  return (
    <StyledMediaOverviewWrapper>
      <div className="flex min-h-full">
        <OffCanvasNav
          categories={filteredCategories}
          onOpen={openNavigation}
          onClose={closeNavigation}
          isOpen={showNavigation}
          naviWidth={naviWidth}
          className="top-0"
        />

        <StyledScrollWrapper
          className={`${showNavigation ? 'ml-72' : ''} min-h-screen`}
        >
          <Switch>
            <Route
              exact
              path="/media-library/all-videos"
              component={ShowAllVideosScene}
            />
            <Route
              exact
              path="/media-library/category/:cid"
              component={ShowCategoryVideosScene}
            />
          </Switch>
        </StyledScrollWrapper>
      </div>
    </StyledMediaOverviewWrapper>
  )
}

export default MediaOverviewScene
