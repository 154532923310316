import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const StyledButton = styled.button`
  ${tw`text-gray-500 focus:outline-none active:outline-none`}
  /* Ghost */
   ${({ ghost }) =>
    ghost &&
    tw`
  bg-transparent
  p-3 h-6
  flex items-center justify-center
  text-base
text-gray-500`}

  &:hover {
    ${tw`bg-gray-200`}
  }

  &:active {
    ${tw`bg-gray-300`}
  }

  &.active {
    ${tw`bg-gray-700 text-gray-200`}
  }
`

const BaseButton = ({
  onClick,
  children,
  ghost,
  className,
  disabled,
  ...rest
}) => {
  return (
    <StyledButton
      {...rest}
      ghost={ghost}
      className={className}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  )
}

export default BaseButton
