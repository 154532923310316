import { IconButton, Progress } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import BackButton from 'components/BackButton'
import BreadCrumbs from 'components/BreadCrumbs'
import BookmarkButton from 'components/Button/BookmarkButton'
import Icon from 'components/IconLoader'

const StyledInfoSectionWrapper = styled.div`
  ${tw`fixed top-0 left-16 right-0 z-50`}
`

const StyledInfoSection = styled.div`
  ${tw`flex items-center justify-between px-2 bg-white shadow`}
`

const StyledButtonBox = styled.div``

const StyledBreadcrumbsWrapper = styled.div`
  ${tw`relative w-full`}

  &:before {
    ${tw`absolute top-0 left-0 w-4 h-full bg-gradient-to-r from-white`}
    content: '';
  }

  &:after {
    ${tw`absolute top-0 right-0 w-4 h-full bg-gradient-to-l from-white`}
    content: '';
  }
`

const InfoSection = ({
  category,
  process,
  currentStep,
  bookmark,
  questions,
  matchQuestionsRoute,
  progress,
  allQuestionsCorrect,
}) => {
  return (
    <StyledInfoSectionWrapper>
      <StyledInfoSection>
        <StyledButtonBox className="pr-2 border-r">
          <BackButton />
        </StyledButtonBox>
        <StyledBreadcrumbsWrapper>
          <BreadCrumbs
            category={category}
            process={process}
            currentStep={currentStep}
            questions={questions}
          />
        </StyledBreadcrumbsWrapper>
        <StyledButtonBox className="pl-2 border-l flex items-center">
          {process && process.questions.length && !matchQuestionsRoute ? (
            <IconButton
              icon={<Icon name="acadamic-cap" className="w-6 h-6" />}
              variant="ghost"
              color="uiColors.gray.500"
              mr="2"
              as={Link}
              to={`/categories/${process.category.id}/processes/${process.id}/questions`}
            />
          ) : (
            ''
          )}
          {bookmark && (
            <BookmarkButton item={currentStep ? currentStep : process} />
          )}
        </StyledButtonBox>
      </StyledInfoSection>
      {questions && (
        <Progress
          position="absolute"
          w="full"
          value={progress}
          colorScheme={allQuestionsCorrect ? 'green' : 'blue'}
        />
      )}
    </StyledInfoSectionWrapper>
  )
}

export default InfoSection
