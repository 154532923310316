/**
 * Benutzerdefinierte React-Hook zur Verwaltung der Scroll-Position zwischen Seitenwechseln.
 * Diese Hook-Funktion nutzt das Web-API "sessionStorage" und die React Hooks "useLocation" und "useScrollPosition".
 *
 * @returns {void}
 */
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

/**
 * Stellt die zuvor gespeicherte Scroll-Position für eine URL wieder her.
 * Wenn die gespeicherte Position außerhalb des Dokuments liegt, wird gewartet, bis das Dokument den entsprechenden Bereich gerendert hat,
 * bevor die Scroll-Position wiederhergestellt wird.
 *
 * @param {string} url - Die URL, für die die Scroll-Position wiederhergestellt werden soll.
 * @returns {void}
 */
function restoreScrollPos(url) {
  setTimeout(async () => {
    const userHasClickedBackButton = sessionStorage.getItem('userHasClickedBackButton') || false;
    let rawScrollPos = '0';

    if(userHasClickedBackButton && sessionStorage.getItem(url)){
      rawScrollPos = sessionStorage.getItem(url)
    }

    sessionStorage.removeItem('userHasClickedBackButton');

    const scrollPos = parseInt(JSON.parse(rawScrollPos))
    const clientHeight = window.innerHeight
    let totalScrollHeight = document.documentElement.scrollHeight

    const timeout = 2000 // 2 Sekunden
    const startTime = Date.now()

    while (totalScrollHeight < clientHeight + scrollPos) {
      totalScrollHeight = document.documentElement.scrollHeight
      await new Promise(resolve => setTimeout(resolve, 100))

      const currentTime = Date.now()
      if (currentTime - startTime >= timeout) {
        break
      }
    }

    window.scrollTo({ left: 0, top: scrollPos })
  }, 0)
}

/**
 * Verwendet das Web-API "sessionStorage", um die Scroll-Position zwischen Seitenwechseln zu speichern und wiederherzustellen.
 * Das Web-API "history.scrollRestoration" wird auf "manual" gesetzt, um das Standardverhalten des Browsers für das Scrollen zu überschreiben.
 * Die Hook-Funktion nutzt auch "useLocation" und "useScrollPosition" aus "react-router-dom", um die Scroll-Position für bestimmte URLs zu ermitteln.
 *
 * @returns {void}
 */
export default function useScrollRestoration() {
  window.history.scrollRestoration = 'manual'

  const location = useLocation()

  useEffect(() => {
    // restore scroll on entering page
    restoreScrollPos(location.pathname)
  }, [location.pathname])

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        sessionStorage.setItem(
          "userHasClickedBackButton",
          true
        );
      }
    });

    return () => {
      unlisten(); // Event-Listener bei Komponentenaufräumung entfernen
    };
  }, [history]);
}
