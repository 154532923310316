import { useBookmarks } from 'data/bookmarks'
import React from 'react'
import { ArrowRight, Bookmark, ChevronRight } from 'react-feather'
import { Link } from 'react-router-dom'

import DashboardBox from '../DashboardBox'
import styles from './index.module.css'
import {
  getBookmarkPath,
  getBookmarkTitle,
  getBookmarkUrl,
} from 'scenes/Bookmarks'

const Component = ({ box }) => {
  const { bookmarks, isLoading: isBookmarksLoading } = useBookmarks()

  let options = JSON.parse(box?.options || '{}')

  return (
    <DashboardBox isLoading={isBookmarksLoading}>
      <DashboardBox.Header
        icon={<Bookmark size={24} />}
        title={options?.title || 'Lesezeichen'}
      />
      <DashboardBox.Content
        noContentText="Es sind keine Lesezeichen vorhanden."
        hasNoContent={bookmarks?.length < 1 || !bookmarks}
      >
        {bookmarks &&
          bookmarks.slice(0, options.max || 3).map((bookmark, key) => {
            return (
              <div className={styles.wrapper} key={key}>
                <Bookmark
                  className={`${styles.icon} icon-fill icon-stroke`}
                  style={{ color: '#64748b' }}
                  size={27}
                />
                <Link className={styles.link} to={getBookmarkUrl(bookmark)}>
                  <div className={styles.textWrapper}>
                    <span className={styles.path}>
                      {getBookmarkPath(bookmark)}
                    </span>
                    <span className={styles.title}>
                      {getBookmarkTitle(bookmark)}
                    </span>
                  </div>
                  <ChevronRight size={24} className={styles.iconGray} />
                </Link>
              </div>
            )
          })}
      </DashboardBox.Content>
      {
        <DashboardBox.Footer>
          <Link className={styles.link} to={`/bookmarks`}>
            <DashboardBox.Footer.Button>
              Alle anzeigen
              <ArrowRight className="ml-2" size={20} stroke="#64748B" />
            </DashboardBox.Footer.Button>
          </Link>
        </DashboardBox.Footer>
      }
    </DashboardBox>
  )
}

export default Component
