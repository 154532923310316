import { Progress } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import ProgressiveImage from 'react-progressive-image'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useBackButton } from 'context/back-button'
import { useCategory } from 'data/categories'
import theme from 'theme'

import Container from 'components/Container'
import ImagePlaceholder from 'components/ImagePlaceholder'
import InfoSection from 'components/InfoSection'
import LoadingLayer from 'components/LoadingLayer'
import ScrollWrapper from 'components/ScrollWrapper'
import UnpublishedIcon from 'components/UnpublishedIcon'

const { REACT_APP_API_URL: API_URL } = process.env

const StyledProcessItem = styled(Link)`
  ${tw`relative flex bg-white border rounded-lg border-gray-200 overflow-hidden`}
  ${tw`transition-shadow duration-200 ease-out shadow-md hover:shadow-lg`}
`

const StyledFirstProcessSection = styled.section`
  ${tw`relative p-4 pb-20 lg:pb-24 -mb-28`}
  background-color: ${theme.default.colors.primary};

  &:before {
    ${tw`absolute inset-0 block`}
    content: '';

    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 50%
    );
  }
`

const StyledWrapper = styled.div`
  ${tw`flex flex-col mt-14`}
`

const ProcessItem = ({ process, isFirst = false, className }) => {
  const processUrl = `/categories/${process.category.id}/processes/${process.id}`

  const questionsCount = process.questions_count
  const answeredQuestionsCount = process.answered_questions_count
  const answeredQuestionsCorrectCount = process.answered_questions_correct_count

  const hasQuestions = questionsCount > 0
  const allQuestionsRight =
    answeredQuestionsCount === questionsCount &&
    answeredQuestionsCount === answeredQuestionsCorrectCount
  const questionProgress = (answeredQuestionsCount / questionsCount) * 100

  const renderProcessImage = media => {
    if (!media || !media.image_formats)
      return <ImagePlaceholder className="absolute h-full" />

    // Find tiny format and save as object in tinyImage
    let tinyImage = media.image_formats.find(format => format.type === 'tiny')
    // Fallback for tinyImage
    tinyImage = tinyImage ? tinyImage : media

    // Find large format and save as object in largeImage
    let largeImage = media.image_formats.find(format => format.type === 'large')
    // Fallback for largeImage
    largeImage = largeImage ? largeImage : media

    return (
      <ProgressiveImage
        src={`${API_URL}${largeImage.media_url}`}
        placeholder={`${API_URL}${tinyImage.media_url}`}
      >
        {(src, loading) => (
          <img
            className="w-full transition-all duration-200 absolute block object-cover h-full"
            style={{
              filter: loading ? 'blur(10px)' : 'blur(0)',
              opacity: loading ? 0.5 : 1,
            }}
            src={src}
            alt={process.name}
          />
        )}
      </ProgressiveImage>
    )
  }

  return (
    <StyledProcessItem className={className} to={processUrl}>
      {isFirst ? (
        <div to={processUrl} className="relative block w-2/5 h-auto">
          {renderProcessImage(process.media)}
        </div>
      ) : (
        ''
      )}
      <div className="flex-1 p-4 lg:p-8 w-full">
        <div className="flex">
          <div className="flex-1 w-full">
            <div className={`flex items-center ${isFirst && 'mb-40'}`}>
              <div className="flex items-center w-full truncate">
                <h2 className="w-full truncate text-3xl font-normal mb-0">
                  <UnpublishedIcon entity={process} />
                  <span className="truncate">{process.name}</span>
                </h2>
              </div>
            </div>
            {hasQuestions && (
              <div className="mt-1">
                <div className="block mb-1 text-gray-400 text-sm">
                  <span>Lernfortschritt</span>
                </div>
                <Progress
                  className="rounded"
                  value={questionProgress}
                  height="2"
                  colorScheme={allQuestionsRight ? 'green' : 'blue'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledProcessItem>
  )
}

const CategoryScene = () => {
  const { cid } = useParams()
  const { setBackTo } = useBackButton()

  const {
    category,
    isLoading: isCategoryLoading,
    isError: isCategoryError,
    isForbidden: isCategoryForbidden,
    mutate: mutateCategory,
  } = useCategory(cid)

  // Set path where back button should lead to
  useEffect(() => {
    setBackTo('/categories')
  }, [setBackTo])

  if (isCategoryLoading || isCategoryError || isCategoryForbidden)
    return (
      <LoadingLayer
        isError={isCategoryError}
        isLoading={isCategoryLoading}
        isForbidden={isCategoryForbidden}
      />
    )

  const { processes } = category
  const publishedProcesses = processes.length > 0
  const firstProcess = processes[0]
  const processesWithoutFirst = processes.filter((p, i) => i > 0)

  return (
    <StyledWrapper>
      <InfoSection category={category} />
      <ScrollWrapper>
        {publishedProcesses ? (
          <div>
            <StyledFirstProcessSection>
              <Container>
                <ProcessItem
                  isFirst
                  process={firstProcess}
                  progress={firstProcess.result_percentage}
                  mutateCategory={mutateCategory}
                />
              </Container>
            </StyledFirstProcessSection>
            <section>
              <Container>
                {processesWithoutFirst.map(process => {
                  return (
                    <ProcessItem
                      key={process.id}
                      process={process}
                      progress={process.result_percentage}
                      mutateCategory={mutateCategory}
                      className="mb-4"
                    />
                  )
                })}
              </Container>
            </section>
          </div>
        ) : (
          <div>
            <Container>
              <p>Keine Prozesse vorhanden</p>
            </Container>
          </div>
        )}
      </ScrollWrapper>
    </StyledWrapper>
  )
}

export default CategoryScene
