import { Button } from '@chakra-ui/react'
import React, { useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import styled from 'styled-components'
import tw from 'twin.macro'

import Container from 'components/Container'
import FlexGrid from 'components/FlexGrid'
import LoadingLayer from 'components/LoadingLayer'
import Video from 'components/MediaLibrary/Video'

import { useVideos } from 'data/videos'

const GridItem = styled.div`
  ${tw`w-full p-4 md:w-1/2`}
`

const ShowAllVideosScene = () => {
  const [pageIndex, setPageIndex] = useState(1)

  const { videos, isLoading, isError } = useVideos(pageIndex)

  if (isLoading || isError) {
    return <LoadingLayer isError={isError} isLoading={isLoading} />
  }

  return (
    <Container className="flex flex-col flex-grow">
      <h1>Alle Videos</h1>
      {videos && videos.data && videos.data.length > 0 && (
        <>
          <FlexGrid className="-m-4 flex-grow">
            {videos.data.map(
              video =>
                video.category && (
                  <GridItem key={video.id}>
                    <Video video={video} />
                  </GridItem>
                )
            )}
          </FlexGrid>
          <nav className="border-t border-gray-200 flex items-center justify-between py-4 mt-8 font-normal">
            <div className={'w-1/3 flex justify-start text-gray-500'}>
              {pageIndex > 1 && (
                <Button
                  variant="ghost"
                  fontWeight="normal"
                  leftIcon={<ChevronLeft size={24} strokeWidth={1} />}
                  onClick={() => setPageIndex(pageIndex - 1)}
                >
                  Vorherige Seite
                </Button>
              )}
            </div>
            <div className={'w-1/3 text-gray-500 text-center'}>
              Seite {pageIndex} von {videos.last_page}
            </div>
            <div className={'w-1/3 flex justify-end text-gray-500'}>
              {pageIndex !== videos.last_page && (
                <Button
                  variant="ghost"
                  fontWeight="normal"
                  rightIcon={<ChevronRight size={24} strokeWidth={1} />}
                  onClick={() => setPageIndex(pageIndex + 1)}
                >
                  Nächste Seite
                </Button>
              )}
            </div>
          </nav>
        </>
      )}
    </Container>
  )
}

export default ShowAllVideosScene
