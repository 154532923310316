import ProcessNormalizer from './ProcessNormalizer'
import isEmpty from 'lodash/isEmpty'

/**
 * Class representing a StepNormalizer.
 * Normalizes a given step object by making specific modifications based on conditions.
 */
class StepNormalizer {
  /**
   * @constructor
   * @param {Object} step - The step object.
   */
  constructor(step) {
    this.step = step ?? {}
  }

  /**
   * The normalization process involves:
   * - Normalizing linked_process.
   * - Normalizing linked_category.processes.
   * - Recursively normalizing linked_step.
   * - Recursively normalizing children_sorted.
   *
   * Finally, it returns the transformed step object, with the modified title, that is the modified_title or title conditionally.
   * @type {Object}
   */
  get transformedStep() {
    const isUnpublishedStep = !this.step.published_at

    if (this.step.linked_step) {
      this.step.linked_step = StepNormalizer.normalize(this.step.linked_step)
    }
    if (this.step.linked_process) {
      this.step.linked_process = ProcessNormalizer.normalize(
        this.step.linked_process
      )
    }
    if (
      this.step.linked_category?.processes &&
      !isEmpty(this.step.linked_category.processes)
    ) {
      this.step.linked_category.processes = this.step.linked_category.processes.map(
        process => ProcessNormalizer.normalize(process)
      )
    }

    if (this.step.children_sorted) {
      this.step.children_sorted = this.step.children_sorted.map(step =>
        StepNormalizer.normalize(step)
      )
    }

    return Object.assign(this.step, {
      title: isUnpublishedStep
        ? this.step.modified_title || this.step.title
        : this.step.title,
    })
  }

  /**
   * Normalize the given step object.
   * Allows you to normalize a step object without creating an instance of `StepNormalizer`.
   * @static
   * @param {Object} step - The step object to normalize.
   * @returns {Object} The normalized step object.
   */
  static normalize(step) {
    const instance = new StepNormalizer(step)

    return instance.transformedStep
  }
}

export default StepNormalizer
