import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import { PlayCircle } from 'react-feather'
import ReactPlayer from 'react-player'

const { REACT_APP_API_URL: API_URL } = process.env

const VideoWrapper = styled.div`
  ${tw`w-full overflow-hidden no-underline transition-shadow duration-200 ease-out bg-white border rounded-lg shadow-md border-gray-200 hover:shadow-lg`}
`

const StyledReactPlayer = styled(ReactPlayer)`
  ${tw`relative`}

  & > div,
  & > video {
    ${tw`absolute inset-0 h-full`}
  }

  & svg {
    ${tw`h-auto w-1/5 bg-white bg-opacity-50 rounded-full transition-colors duration-100`}
  }

  &:hover,
  &:focus {
    & svg {
      ${tw`bg-opacity-75`}
    }
  }

  &:active {
    & svg {
      ${tw`bg-opacity-100`}
    }
  }
`

const Video = ({ video }) => {
  return (
    <VideoWrapper>
      <StyledReactPlayer
        style={{ paddingTop: '56.25%' }}
        width="100%"
        height="auto"
        url={`${API_URL}${video.video_url}`}
        light={`${API_URL}${video.thumb_url}`}
        controls={true}
        muted
        pip={false}
        playIcon={<PlayCircle size={100} strokeWidth={0.5} />}
      />
      <div className="px-4 py-4">
        <span>{video.name}</span>
      </div>
    </VideoWrapper>
  )
}

export default Video
