import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import ForbiddenMessage from 'components/ForbiddenMessage'
import ErrorMessage from 'components/ErrorMessage'
import LoadingSpinner from 'components/LoadingSpinner'
import { Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const StyledLoadingLayer = styled.div`
  ${tw`flex justify-center items-center w-full flex-col`}
  height: 100vh;
`

const LoadingLayer = ({ isForbidden, isError, isLoading, className }) => {
  return (
    <StyledLoadingLayer className={className}>
      {isForbidden ? (
        <ForbiddenMessage />
      ) : isError ? (
        <ErrorMessage />
      ) : isLoading ? (
        <LoadingSpinner />
      ) : null}

      {isForbidden || isError ? (
        <Button className="mt-4" as={Link} to="/categories/">
          Zurück zur Kategorie-Übersicht
        </Button>
      ) : null}
    </StyledLoadingLayer>
  )
}

export default LoadingLayer
