import axios from 'axios'

export const fetchOpenGraphData = ({
  url,
  allMeta,
  lang,
  options,
  userAgent,
}) => {
  if (!url) throw new Error('url is required')

  return axios.post('/open-graph-data', {
    data: { url, allMeta, lang, options, userAgent },
  })
}

export const getFavicon = url => {
  if (!url) throw new Error('url is required')

  return `https://www.google.com/s2/favicons?domain=${url}`
}
