import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

const StyledBreadcrumbs = styled.ul`
  ${tw`flex items-center flex-no-wrap py-4`}

  & li {
    ${tw`whitespace-no-wrap`}
  }

  & li:not(:last-child) {
    ${tw``}

    &:after {
      ${tw`mx-1`}
      content: '/';
    }
  }

  & li:first-child {
    ${tw`pl-4`}
  }

  & li:last-child {
    ${tw`pr-4`}
  }

  & a {
    ${tw`hover:underline`}
  }

  & span {
    ${tw`text-gray-400`}
  }
`

const BreadCrumbItem = ({ processUrl, step, isLast, wrapperRef }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (wrapperRef && wrapperRef.current && itemRef && itemRef.current) {
      wrapperRef.current.scrollLeft = itemRef.current.offsetLeft
    }
  }, [wrapperRef])

  return (
    <li ref={itemRef}>
      {isLast ? (
        <span>{step.title}</span>
      ) : (
        <Link to={`${processUrl}/${step.id}`}>{step.title}</Link>
      )}
    </li>
  )
}

const BreadCrumbs = ({ category, process, currentStep, questions }) => {
  const { cid, pid } = useParams()
  const matchCategoriesRoute = useRouteMatch(`/categories`)
  const matchCategoryRoute = useRouteMatch(`/categories/${cid}`)
  const [path, setPath] = useState([])
  const breadcrumbRef = useRef()

  const buildPath = useCallback(
    (step, currentPath = []) => {
      // kein Step? Kein Path!
      if (!step) return []
      // aktuellen step in path[] pushed
      currentPath.push(step)
      // wenn step.parent_id
      if (step.parent_id) {
        // in process.steps_flat den passenden schritt finden
        const parentStep = process.steps_flat.find(
          item => item.id === step.parent_id
        )

        // und den ganzen mist nochmal aufrufen
        return buildPath(parentStep, currentPath)
      }

      return currentPath
    },
    [process]
  )

  useEffect(() => {
    if (process && process.steps_flat) {
      setPath(buildPath(currentStep).reverse())
    }
  }, [process, currentStep, buildPath])

  const isNotRoot = category || process
  const categoryUrl = `/categories/${cid}`
  const processUrl = `${categoryUrl}/processes/${pid}`

  let categoryAndTopicName = ''
  if (isNotRoot) {
    categoryAndTopicName = process
      ? `${process.category.topic.name} / ${process.category.name}`
      : `${category.topic.name} / ${category.name}`
  }

  return (
    <StyledBreadcrumbs ref={breadcrumbRef}>
      <li>
        {matchCategoriesRoute.isExact ? (
          <span>Themen und Kategorien</span>
        ) : (
          <Link to="/categories">Themen und Kategorien</Link>
        )}
      </li>
      {isNotRoot && (
        <li>
          {matchCategoryRoute.isExact ? (
            <span>{categoryAndTopicName}</span>
          ) : (
            <Link to={categoryUrl}>{categoryAndTopicName}</Link>
          )}
        </li>
      )}
      {process && (
        <li>
          {path.length === 0 && !questions ? (
            <span>{process.name}</span>
          ) : (
            <Link to={processUrl}>{process.name}</Link>
          )}
        </li>
      )}
      {path &&
        path.map((step, i) => {
          const isLast = i + 1 === path.length

          return (
            <BreadCrumbItem
              key={step.id}
              wrapperRef={breadcrumbRef}
              processUrl={processUrl}
              step={step}
              isLast={isLast}
            />
          )
        })}
      {questions && <span>Fragebogen</span>}
    </StyledBreadcrumbs>
  )
}

export default BreadCrumbs
