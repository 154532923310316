import PropTypes from 'prop-types'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import IconButton from 'components/Button/IconButton'
import CategoriesTOC from 'components/Category/CategoriesTOC'
import ProcessTOC from 'components/Process/ProcessTOC'

const StyledOffCanvasNav = styled.div`
  ${tw`fixed left-16 bottom-0 z-20 bg-gray-100`}

  flex: 0 0 ${({ naviWidth }) => naviWidth};
  width: ${({ naviWidth }) => naviWidth};

  transition: margin-left 0.5s, margin-right 0.5s;
  margin-left: ${({ isOpen, naviWidth }) => (isOpen ? '0' : `-${naviWidth}`)};
  margin-right: ${({ isOpen }) => (isOpen ? '0' : `2rem`)};
`

const StyledMenuButton = styled(IconButton)`
  ${tw`p-2 rounded-full focus:outline-none`}
  ${({ isOpen }) => isOpen && tw`rounded`}
`

const StyledButtonWrapper = styled.div`
  ${tw`absolute right-0 mt-2 bg-gray-100 transition-all duration-500 ease-out p-1 rounded-r-full z-10 shadow -mr-12`}
  ${({ isOpen }) => isOpen && tw`mr-2 rounded-none border-0 shadow-none`}
`

const OffCanvasNav = ({
  isOpen,
  categories,
  process,
  naviWidth,
  onOpen,
  onClose,
  className = '',
  currentStep = null,
}) => {
  let { url } = useRouteMatch()

  return (
    <StyledOffCanvasNav
      className={className}
      isOpen={isOpen}
      naviWidth={naviWidth}
    >
      <StyledButtonWrapper isOpen={isOpen}>
        <StyledMenuButton
          icon={isOpen ? 'x' : 'menu'}
          className="p-2 rounded-full focus:outline-none"
          isOpen={isOpen}
          onClick={() => {
            isOpen ? onClose() : onOpen()
          }}
        />
      </StyledButtonWrapper>
      <div className="relative h-full overflow-y-auto">
        {process && (
          <ProcessTOC
            className="ProcessTOC"
            process={process}
            baseUrl={url}
            currentStep={currentStep}
          />
        )}
        {categories && <CategoriesTOC categories={categories} />}
      </div>
    </StyledOffCanvasNav>
  )
}

OffCanvasNav.propTypes = {
  isOpen: PropTypes.bool,
  toggleShowNavigation: PropTypes.func,
}

export default OffCanvasNav
