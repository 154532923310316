import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import Renderer from '../Renderer'

const TableWrapper = styled.div`
  ${tw`w-full overflow-x-auto`}

  & table {
    ${tw`border-collapse table-fixed w-full mb-2`}
  }

  & tr:first-child td {
    ${tw`border-t`}
  }

  & td {
    ${tw`border-gray-300 py-3 px-3 border-b border-r`}

    &:first-child {
      ${tw`border-l`}
    }

    & p {
      ${tw`m-0`}
    }
  }
`

const StyledTd = styled.td`
  > div {
    ${tw`items-start`}
  }
`

const Table = props => {
  const { children = [], index = 0, colSizes = [] } = props

  return (
    <TableWrapper>
      <table>
        <tbody>
          {children.map((tr, trIndex) => {
            const trKey = `${index}-tr-${trIndex}`
            return (
              <tr key={trKey}>
                {tr.children.map((td, tdIndex) => {
                  const tdKey = `${index}-${trIndex}-td-${tdIndex}`
                  const width = colSizes[tdIndex] || 'auto'

                  const borderStyles = {}
                  if (td.borders) {
                    Object.keys(td.borders).forEach(key => {
                      const { size } = td.borders[key]
                      const upperCaseKey =
                        key.charAt(0).toUpperCase() + key.slice(1)

                      borderStyles[`border${upperCaseKey}Width`] = `${size}px`
                    })
                  }

                  return (
                    <StyledTd key={tdKey} width={width} style={borderStyles}>
                      {td.children.map((child, cellIndex) => {
                        const key = `${index}-${trIndex}-${tdIndex}-cell-${cellIndex}`
                        return (
                          <Renderer
                            key={key}
                            content={child}
                            startIndex={key}
                          />
                        )
                      })}
                    </StyledTd>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </TableWrapper>
  )
}

export default Table
