import { useMemo } from 'react'
import useSWR from 'swr'
import ProcessNormalizer from './utils/ProcessNormalizer'

export const useProcesses = () => {
  const { data, mutate, error } = useSWR(`/processes`)

  const normalizedProcesses = useMemo(() => {
    return (
      data &&
      data.data &&
      data.data.map(process => ProcessNormalizer.normalize(process))
    )
  }, [data])

  return {
    processes: normalizedProcesses,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}

export const useProcess = id => {
  const { data, mutate, error } = useSWR(id ? `/processes/${id}` : null)

  const normalizedProcess = useMemo(() => {
    return data && ProcessNormalizer.normalize(data.data)
  }, [data])

  return {
    process: normalizedProcess,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}
