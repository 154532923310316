import { ChakraProvider } from '@chakra-ui/react'
import axios from 'axios'
import chakraTheme from 'chakraTheme'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { SWRConfig } from 'swr'

import App from 'App'
import GlobalStyle from 'GlobalStyle'
import theme from 'theme'
import * as serviceWorker from './serviceWorker'

import AuthService from 'services/AuthService'

import 'styles/index.css'

// Axios default configurations
axios.defaults.baseURL = process.env.REACT_APP_API_URL ?? ''
axios.defaults.withCredentials = true

// Intercept 401 && 403 errors globally
axios.interceptors.response.use(
  response => response,
  error => {
    if (
      error &&
      error.response &&
      error.response.config &&
      error.response.config.url === '/me?authcheck'
    ) {
      AuthService.resetAuth()

      return Promise.reject(error)
    }

    if (error && error.response && [401, 403].includes(error.response.status)) {
      // check if am am still logged in
      // and if not reset auth and reload
      axios.get('/me?authcheck').catch(() => {
        AuthService.resetAuth()
        window.location.reload()
      })
    }

    return Promise.reject(error)
  }
)

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        fetcher: url => axios.get(url).then(res => res.data),
      }}
    >
      <ChakraProvider theme={chakraTheme}>
        <ThemeProvider theme={theme}>
          <Router>
            <App />
          </Router>
          <GlobalStyle />
        </ThemeProvider>
      </ChakraProvider>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
