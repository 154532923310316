class MeNormalizer {
  constructor(me) {
    this.me = me || {}
  }

  get transformedReview() {
    const name = this.me?.name

    this.me.formattedName = name

    if (name?.includes(',')) {
      // if name is in the format of "Smith, John" change it to "John Smith" else leave it as it is
      const [lastName, firstName] = name?.split(',') ?? []
      this.me.formattedName = `${firstName.trim()} ${lastName?.trim()}`
    }

    return this.me
  }

  static normalize(me) {
    const instance = new MeNormalizer(me)
    return instance.transformedReview
  }
}

export default MeNormalizer
