import { useProcess } from 'data/processes'
import useStep from 'data/steps'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

const Frame = styled.div`
  ${tw`absolute rounded`}
  ${tw`transition-all duration-200 ease-out border-4 border-blue-300 hover:border-blue-600`}
`

const GraphicStepLink = styled(NavLink)`
  ${tw`block w-full h-full`}
`

const ProcessFrame = ({ frame }) => {
  const { top, left, width, height } = frame

  const stepId = frame.step.id ?? null
  const { step, isError: isErrorStep } = useStep(stepId)

  const process_id = step && step.process_id
  const { process, isError: isErrorProcess } = useProcess(process_id)

  if (isErrorProcess || isErrorStep) {
    return null
  }

  const stepUrl =
    step && process
      ? `/categories/${process.category.id}/processes/${process.id}/${step.id}`
      : null

  return stepUrl ? (
    <Frame
      style={{
        top: top + '%',
        left: left + '%',
        width: width + '%',
        height: height + '%',
      }}
    >
      {stepUrl ? <GraphicStepLink to={stepUrl} /> : null}
    </Frame>
  ) : null
}

export default ProcessFrame
