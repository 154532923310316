import { createContext, useContext } from 'react'

export const AuthContext = createContext({
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  checkLogin: () => {},
})

export function useAuth() {
  return useContext(AuthContext)
}
