import useSWR from 'swr'
import AuthService from 'services/AuthService'
import axios from 'axios'

export const useNotifications = () => {
  const { data, mutate, error } = useSWR(`/notifications`)

  return {
    notifications: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}

export const useUnreadNotificationsCount = () => {
  const { data, mutate, error } = useSWR(
    AuthService.getAuth() && `/get-unread-notifications-count`,
    {
      refreshInterval: 5000, // refresh notificationscount on interval (5s)
    }
  )

  return {
    count: data?.data?.unread_notifications,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}

export const setRead = notification_ids => {
  return axios.post('/set-notifications-read', {
    data: {
      notification_ids,
    },
  })
}

export const deleteNotifications = notification_ids => {
  return axios.post('/delete-notifications', {
    data: {
      notification_ids,
    },
  })
}
