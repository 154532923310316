import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

const TOC = styled.div`
  a {
    ${tw`flex items-center px-3 py-2 rounded mb-px transition-all duration-100 ease-out`}

    &:hover {
      ${tw`bg-gray-200`}
    }

    &.active {
      ${tw`bg-gray-200`}
    }

    &:active {
      ${tw`bg-gray-300`}
    }

    & svg {
      ${tw`w-4 h-4`}
    }

    & span {
      ${tw`truncate`}
    }
  }
`

const CategoriesTOC = ({ className, categories }) => {
  console.log(categories)
  return (
    <TOC className={className}>
      <div className="mb-2 pb-2 border-b sticky top-0 p-3 bg-gray-100">
        <NavLink
          className="flex items-center mr-12"
          to={`/media-library/all-videos`}
        >
          {/*<Play size={40}/>*/}
          <span>Alle Videos</span>
        </NavLink>
      </div>
      {categories &&
        categories.map(category => (
          <div className="mb-2 mx-3">
            <NavLink
              key={category.id}
              to={`/media-library/category/${category.id}`}
            >
              <span className="block truncate">{category.name}</span>
            </NavLink>
          </div>
        ))}
    </TOC>
  )
}

export default CategoriesTOC
