import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useLocation } from 'react-router-dom'

import { useStep } from 'data/steps'

import Container from 'components/Container'
import LoadingLayer from 'components/LoadingLayer'
import ProcessStepRenderer from 'components/ProcessStepRenderer'
import { LinkedReference } from 'components/Steps/LinkedReference'
import UnpublishedIcon from 'components/UnpublishedIcon'
import { useDate } from 'hooks/use-format-date'
import { FormControl, FormLabel, Switch } from '@chakra-ui/react'

const StyledStepContentWrapper = styled.div`
  ${tw`flex-col flex-grow flex-shrink-0 bg-white whitespace-pre-wrap`}

  & img {
    ${tw`max-w-full`}
  }

  & p {
    ${tw`mb-2 min-h-4`}
  }

  & ol {
    ${tw`pl-4 mt-2 mb-4 list-decimal`}
  }

  & ul {
    ${tw`pl-4 mt-2 mb-4 list-disc`}

    & ul {
      ${tw`my-0`}
    }
  }
`

const ShowProcessStep = ({ setStepInBreadcrumb }) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const isPreview = queryParams.get('preview') === 'true'

  const { sid } = useParams()
  const { step, isLoading: isStepLoading, isError: isStepError } = useStep(sid)

  const hasPreview = isPreview && Boolean(step?.modified_content)
  const canSwitchBetweenStates = hasPreview && step?.published_at

  if (!localStorage.getItem('showEditState')) {
    localStorage.setItem('showEditState', hasPreview)
  }

  const [showEditState, setShowEditState] = useState(
    localStorage.getItem('showEditState') === 'true'
  )
  const showEditStateWithPublish = showEditState || !step?.published_at
  const [content, setContent] = useState(
    step?.content ? JSON.parse(step.content) : undefined
  )
  const [title, setTitle] = useState(step?.title)

  useEffect(() => {
    if (!step) return

    if (showEditStateWithPublish) {
      setContent(JSON.parse(step.modified_content))
      setTitle(step.modified_title)
    } else {
      setContent(JSON.parse(step.content))
      setTitle(step.title)
    }
  }, [step, showEditStateWithPublish, hasPreview])

  const updateStepInBreadcrumb = useCallback(
    step => {
      setStepInBreadcrumb(step)
    },
    [setStepInBreadcrumb]
  )
  const date = useDate(step?.published_at, false)
  // Setze aktuellen Step für Breadcrumb
  useEffect(() => {
    if (!isStepLoading && !isStepError && step) {
      updateStepInBreadcrumb(step)
    }
  }, [isStepLoading, isStepError, step, updateStepInBreadcrumb])

  if (isStepLoading || isStepError)
    return <LoadingLayer isError={isStepError} isLoading={isStepLoading} />

  return (
    <Container className="bg-white">
      {canSwitchBetweenStates ? (
        <FormControl
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <FormLabel htmlFor="preview-switch">
            Unveröffentlichten Zustand ansehen?
          </FormLabel>
          <Switch
            id="preview-switch"
            isChecked={showEditState}
            onChange={({ target }) => {
              setShowEditState(target.checked)
              localStorage.setItem('showEditState', target.checked)
            }}
          />
        </FormControl>
      ) : hasPreview ? (
        <div className="flex justify-end">
          <span className="text-gray-500">Vorschau</span>
        </div>
      ) : null}

      <div className="flex items-start w-full justify-between">
        <div className="flex">
          <h1>
            <UnpublishedIcon entity={step} />
            {title}
          </h1>
        </div>

        {!showEditStateWithPublish && date && (
          <span className="text-gray-500">Veröffentlicht: {date}</span>
        )}
      </div>
      <LinkedReference step={step} showEditState={showEditStateWithPublish} />
      <StyledStepContentWrapper>
        <ProcessStepRenderer content={content} />
      </StyledStepContentWrapper>
    </Container>
  )
}

export default ShowProcessStep
