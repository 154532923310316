import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const StyledScrollWrapper = styled.div`
  ${tw`flex flex-col w-full h-full`}
`

const ScrollWrapper = ({ children, className = '' }) => (
  <StyledScrollWrapper className={className}>{children}</StyledScrollWrapper>
)

export default ScrollWrapper
