import React from 'react'

import BaseButton from './BaseButton'

const Button = ({ className, onClick, label, ...rest }) => {
  return (
    <BaseButton className={className} onClick={onClick} {...rest}>
      {label}
    </BaseButton>
  )
}

export default Button
