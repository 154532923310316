import { useMemo } from 'react'
import useSWR from 'swr'
import StepNormalizer from './utils/StepNormalizer'

export const useStep = id => {
  const { data, mutate, error } = useSWR(id ? `/steps/${id}` : null)
  const normalizedStep = useMemo(
    () => data && StepNormalizer.normalize(data.data),
    [data]
  )

  return {
    step: normalizedStep,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}

export default useStep
