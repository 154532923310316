import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import IconLoader from 'components/IconLoader'

const StyledForbiddenError = styled.div`
  ${tw`text-3xl text-gray-300 flex items-center flex-col font-semibold`}
`

const StyledIconLoader = styled(IconLoader)`
  ${tw`w-32 h-32 text-gray-200`}
`

const ForbiddenMessage = () => {
  return (
    <StyledForbiddenError>
      <StyledIconLoader name="exclamation" />
      <span>Keine Berechtigungen</span>
    </StyledForbiddenError>
  )
}

export default ForbiddenMessage
