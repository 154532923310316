import axios from 'axios'

export const examineQuestion = (id, givenAnswers) => {
  if (!id || !givenAnswers) return

  return axios.post(`/elearning/questions/${id}/exam`, {
    data: {
      given_answers: givenAnswers,
    },
  })
}

export const resetExam = processId => {
  if (!processId) return

  return axios.post('/elearning/reset-exam', {
    data: { process_id: processId },
  })
}
