import { extendTheme } from '@chakra-ui/react'

const uiColors = require('@tailwindcss/ui/colors')

// const customIcons = {
//   settings: Settings,
// }

/** Accessible color overrides for less accessible colors. */
// const accessibleColorMap = {
//   yellow: {
//     bg: 'yellow.400',
//     color: 'black',
//     hoverBg: 'yellow.500',
//     activeBg: 'yellow.600',
//   },
//   cyan: {
//     bg: 'cyan.400',
//     color: 'black',
//     hoverBg: 'cyan.500',
//     activeBg: 'cyan.600',
//   },
// }

const fonts = [
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Source Sans Pro',
  'system-ui',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  '"Noto Sans"',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
  '"Noto Color Emoji"',
]

// Let's say you want to add custom colors
const customTheme = extendTheme({
  styles: {
    global: {
      html: { height: '100%' },
      body: {
        backgroundColor: 'gray.50',
        color: 'gray.900',
        overflowX: 'hidden',
      },
      'h1, h2, h3, h4, h5, h6': { fontWeight: 'semibold' },
      h1: { fontSize: '4xl', marginBottom: '7' },
      h2: { fontSize: '2xl', marginBottom: '5' },
      h3: { fontSize: 'xl', marginBottom: '4' },
      h4: { fontSize: 'xl', marginBottom: '4' },
      h5: { fontSize: 'lg', marginBottom: '4' },
      h6: { fontSize: 'normal', marginBottom: '4' },
      p: { marginBottom: 4 },
      strong: { fontWeight: 'bold' },
      label: {
        display: 'inline-block',
        fontWeight: 'normal',
        fontSize: '0.875rem',
        marginBottom: 2,
      },
      // ul: { listStyleType: 'disc', listStylePosition: 'inside' },
      // ol: { listStyleType: 'decimal', listStylePosition: 'inside' },
      '#root': { width: '100%', height: '100%' },
      '*, *:before, *:after': { boxSizing: 'border-box' },

      // hacky outline preventing
      // '*:focus': {
      //   boxShadow: 'none !important',
      //   borderColor: 'inherit !important',
      //   outline: 'none !important',
      // },
    },
  },
  fonts: {
    body: fonts.join(),
    heading: fonts.join(),
    mono: 'Menlo, monospace',
  },
  colors: {
    uiColors,
  },
  icons: {},
  components: {},
})

export default customTheme
