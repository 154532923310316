import useSWR from 'swr'

export const useFAQs = () => {
  const { data, error, mutate } = useSWR(`/faqs`)

  return {
    faqs: data && data.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}
