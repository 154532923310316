import { BehaviorSubject } from 'rxjs'
import axios from 'axios'

const AUTH_STORAGE = process.env.REACT_APP_AUTH_STORAGE ?? 'guido-webapp-auth'

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem(AUTH_STORAGE))
)

const setAuth = (user = {}) => {
  // store user details in local storage to keep user logged in between page refreshes
  localStorage.setItem(AUTH_STORAGE, JSON.stringify(user))
  currentUserSubject.next(user)
}

const getAuth = () => {
  return currentUserSubject.value
}

const resetAuth = () => {
  localStorage.removeItem(AUTH_STORAGE)
  currentUserSubject.next(null)
}

const authorizationPreflight = async () => {
  return await axios.get('/me').then(async ({ data }) => {
    setAuth(data)
  })
}

const login = async (email, password) => {
  await axios.get(`/csrf-cookie`)

  await axios
    .post(`/login`, {
      auth: {
        email: email,
        password: password,
      },
    })
    .then(async () => {
      await authorizationPreflight()
    })
    .catch(() => {
      resetAuth()
    })
}

const logout = async () => {
  try {
    await axios.get(`/logout`)

    await resetAuth()
  } catch (e) {
    console.error('Logout failed.')
  }
}

const checkLogin = async () => {
  return axios
    .get('/me?authcheck')
    .then(async ({ data }) => {
      setAuth(data)
    })
    .catch(() => {
      resetAuth()
    })
}

export default {
  setAuth,
  getAuth,
  resetAuth,
  login,
  logout,
  checkLogin,
  user: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value
  },
}
