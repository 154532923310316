import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import Container from 'components/Container'
import Icon from 'components/IconLoader'
import LoadingLayer from 'components/LoadingLayer'
import ScrollWrapper from 'components/ScrollWrapper'

import IconButton from '../../components/Button/IconButton'
import { NotificationContext } from '../../context/notification'
import {
  deleteNotifications,
  setRead,
  useNotifications,
} from '../../data/notifications'

const StyledNotificationWrapper = styled(motion.div)`
  ${tw`flex items-stretch mb-4 transition-all duration-100 rounded-lg bg-white shadow relative divide-x divide-gray-200`}
  ${tw`hover:shadow-lg hover:z-10`}
`

const StyledLink = styled(Link)`
  ${tw`py-2 px-4 rounded-tr-lg rounded-br-lg transition-all duration-100`}
`

const StyledIconButton = styled(IconButton)`
  ${tw`py-2 px-4 active:outline-none focus:outline-none rounded-tl-lg rounded-bl-lg hover:bg-transparent`}
  color: #00adef;

  &.secondary {
    color: #ccc;
  }
`

const StyledPath = styled.span`
  ${tw`text-gray-400 text-sm`}
`

const StyledTitle = styled.div`
  ${tw`mr-2 text-lg block`}
`
const StyledNotificationText = styled.div`
  ${tw`mr-2 text-sm block`}
`

const StyledButton = styled.button`
  ${tw`flex items-center px-4 py-2 mr-2 bg-gray-600 hover:bg-gray-500 active:bg-gray-700 text-gray-100 rounded`}

  & svg {
    ${tw`mr-1 w-5 h-5`}
  }
`

export const getNotificationTitle = notification => {
  if (!notification) return
  let title = ''

  switch (notification.resource.__type) {
    case 'Category':
      title = notification.resource.name
      break
    case 'Process':
      title = notification.resource.name
      break
    case 'Step':
      title = notification.resource.title
      break
    default:
      title = ''
      break
  }

  return title
}

export const getNotificationUrl = notification => {
  if (!notification) return
  let url = ''

  switch (notification.resource.__type) {
    case 'Category':
      url = `/categories/${notification.resource.id}`
      break
    case 'Process':
      url = `/categories/${notification.resource.category.id}/processes/${notification.resource.id}`
      break
    case 'Step':
      url = `/categories/${notification.resource.category.id}/processes/${notification.resource.process.id}/${notification.resource.id}`
      break
    default:
      url = ''
      break
  }

  return url
}

export const getNotificationPath = notification => {
  if (!notification) return
  let path = ''

  switch (notification.resource.__type) {
    case 'Category':
      path = `Themen und Kategorien / ${notification.resource.topic.name}`
      break
    case 'Process':
      path = `Themen und Kategorien / ${notification.resource.topic.name} / ${notification.resource.category.name}`
      break
    case 'Step':
      path = `Themen und Kategorien / ${notification.resource.topic.name} / ${notification.resource.category.name} / ${notification.resource.process.name}`
      break
    default:
      path = ''
      break
  }

  return path
}

const Notification = ({ notification, onRead, onDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [isReading, setIsReading] = useState(false)

  const animationVariants = {
    default: { opacity: notification.read ? 0.5 : 1, x: 0 },
    deleting: { opacity: 0, x: '50%' },
    reading: { opacity: 0.5, x: 0 },
  }

  return (
    <StyledNotificationWrapper
      key={notification.id}
      animate={isDeleting ? 'deleting' : isReading ? 'reading' : 'default'}
      variants={animationVariants}
    >
      <StyledIconButton
        className={!notification.read ? 'icon-fill' : ''}
        icon="message-circle"
        onClick={e => {
          e.stopPropagation()
          setIsReading(true)

          setTimeout(async () => {
            await onRead()
          }, 500)
        }}
      />

      <StyledLink className="flex-1" to={getNotificationUrl(notification)}>
        <StyledPath>{getNotificationPath(notification)}</StyledPath>
        <StyledTitle>{getNotificationTitle(notification)}</StyledTitle>
        <StyledNotificationText>
          {!!notification.text ? notification.text : ''}
        </StyledNotificationText>
      </StyledLink>
      <StyledIconButton
        className="secondary"
        icon="trash-2"
        onClick={async e => {
          e.stopPropagation()
          setIsDeleting(true)

          setTimeout(async () => {
            await onDelete()
            setIsDeleting(false)
          }, 500)
        }}
      />
    </StyledNotificationWrapper>
  )
}

const ShowNotifications = () => {
  const {
    notifications,
    isLoading: isNotificationsLoading,
    isError: isNotificationsError,
    mutate: mutateNotifications,
  } = useNotifications()

  if (isNotificationsLoading || isNotificationsError) {
    return (
      <LoadingLayer
        isError={isNotificationsError}
        isLoading={isNotificationsLoading}
      />
    )
  }

  const setNotificationRead = async notification_ids => {
    await setRead(notification_ids)
    mutateNotifications()
  }

  const deleteNotification = async notification_ids => {
    await deleteNotifications(notification_ids)
    mutateNotifications()
  }

  return (
    <NotificationContext.Consumer>
      {({ count, refreshCount }) => (
        <ScrollWrapper>
          <Container>
            <h1>Benachrichtigungen</h1>
            <div className="flex items-center justify-between">
              <div className="mb-12">
                {count > 0 && (
                  <StyledButton
                    onClick={async e => {
                      e.stopPropagation()
                      await setNotificationRead([-1])
                      refreshCount()
                    }}
                  >
                    <Icon name="message-circle" />
                    <span>
                      Alle {count} Benachrichtigungen als gelesen markieren
                    </span>
                  </StyledButton>
                )}
              </div>

              <div className="mb-12">
                {notifications.length > 0 && (
                  <StyledButton
                    onClick={async e => {
                      e.stopPropagation()
                      await deleteNotification([-1])
                      refreshCount()
                    }}
                  >
                    <Icon name="trash-2" />
                    <span>Alle Benachrichtigungen löschen</span>
                  </StyledButton>
                )}
              </div>
            </div>
            <div>
              {notifications && notifications.length ? (
                notifications.map(notification => {
                  if (!notification) return null

                  return (
                    <Notification
                      key={notification.id}
                      notification={notification}
                      onRead={async () => {
                        await setNotificationRead([notification.id])
                        refreshCount()
                      }}
                      onDelete={async () => {
                        await deleteNotification([notification.id])
                        refreshCount()
                      }}
                    />
                  )
                })
              ) : (
                <div className="italic">
                  Keine Benachrichtigungen vorhanden.
                </div>
              )}
            </div>
          </Container>
        </ScrollWrapper>
      )}
    </NotificationContext.Consumer>
  )
}

export default ShowNotifications
