import useSWR from 'swr'
import isEmpty from 'lodash/isEmpty'
import ProcessNormalizer from './utils/ProcessNormalizer'

export const useCategories = () => {
  // process => ist der node prozess
  const { data, error } = useSWR(`/categories`)

  return {
    categories: data && data.data,
    isLoading: !error && !data,
    isError: error,
  }
}

export const useCategory = id => {
  // process => ist der node prozess
  const { data, error, mutate } = useSWR(id ? `/categories/${id}` : null)

  const category = data && data.data
  if (category && !isEmpty(category.processes)) {
    category.processes = category.processes.map(process =>
      ProcessNormalizer.normalize(process)
    )
  }

  return {
    category,
    isLoading: Boolean(id) && !error && !data,
    isError: Boolean(error),
    isForbidden: error?.response?.status === 403,
    mutate,
  }
}
