import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import AuthService from 'services/AuthService'

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const currentUser = AuthService.currentUserValue

        if (!currentUser) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }

        // authorised so return component
        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute
