import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import { useVideo } from 'data/videos'
import { PlayCircle, VideoOff } from 'react-feather'
import ReactPlayer from 'react-player'

const { REACT_APP_API_URL: API_URL } = process.env

const VideoWrapper = styled.div`
  ${tw`mb-2 w-full no-underline`}
`

const Placeholder = styled.div`
  ${tw`relative bg-gray-100 flex items-center justify-center`}
  aspect-ratio: 16/9;

  & > div {
    ${tw`w-full flex items-center justify-center`}

    & svg {
      ${tw`rounded-none`}
    }
  }

  & svg {
    ${tw`h-auto w-1/5 text-gray-300 bg-opacity-50 rounded-full transition-colors duration-100`}
  }
`

const StyledCaption = styled.p`
  ${tw`mt-2 mb-0 text-gray-600 text-center`}
`

const StyledReactPlayer = styled(ReactPlayer)`
  ${tw`relative`}
  aspect-ratio: 16/9;

  & > div,
  & > video {
  }

  & svg {
    ${tw`h-auto w-1/5 bg-white bg-opacity-50 rounded-full transition-colors duration-100`}
  }

  &:hover,
  &:focus {
    & svg {
      ${tw`bg-opacity-75`}
    }
  }

  &:active {
    & svg {
      ${tw`bg-opacity-100`}
    }
  }
`

const Video = ({ block }) => {
  const { data } = block
  const { video_id } = data
  const { video, isError } = useVideo(video_id)

  return (
    <VideoWrapper>
      {!video && isError && (
        <Placeholder>
          <div>
            <VideoOff size={100} strokeWidth={0.5} />
          </div>
        </Placeholder>
      )}
      {!video && (
        <Placeholder>
          <PlayCircle size={100} strokeWidth={0.5} />
        </Placeholder>
      )}
      {video && (
        <StyledReactPlayer
          className="object-contain"
          width="100%"
          height="100%"
          url={`${API_URL}${video.video_url}`}
          light={`${API_URL}${video.thumb_url}`}
          controls={true}
          pip={false}
          playIcon={<PlayCircle size={100} strokeWidth={0.5} />}
        />
      )}
      {video?.name && <StyledCaption>{video.name}</StyledCaption>}
    </VideoWrapper>
  )
}

export default Video
