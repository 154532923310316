import ImagePlaceholder from 'components/ImagePlaceholder'
import LoadingSpinner from 'components/LoadingSpinner'
import { fetchOpenGraphData, getFavicon } from 'data/website-data'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getMarginHeight } from 'utils/getMargin'

import Link from './Link'
import styles from './LinkPreview.module.css'
import { ExternalLink } from 'react-feather'
import Renderer from '../Renderer'

const VIDEO_FEATURE = false
const DEFAULT_MAX_HEIGHT = 172
const LINE_HEIGHT = 20

function getAvailableHeight(element, parentHeight, exclude = []) {
  if (!element?.parentElement || !parentHeight) return 0
  parentHeight = parentHeight
    ? parentHeight
    : element.parentElement.offsetHeight

  const flexGap = window.getComputedStyle(element.parentElement).gap
  const flexGapHeight = flexGap ? parseInt(flexGap) : 0

  const siblings = Array.from(element.parentElement.children).filter(
    child =>
      child !== element &&
      !Array.from(child.classList).some(c => exclude.includes(c)) &&
      !window.getComputedStyle(child).display.includes('none')
  )

  const flexGapHeightSum = siblings.length * flexGapHeight

  const siblingsHeight = siblings.reduce(
    (acc, child) =>
      acc + (child.offsetHeight || child.clientHeight) + getMarginHeight(child),
    0
  )

  const height = parentHeight - siblingsHeight - flexGapHeightSum

  return height > 0 ? height : 0
}

export default function LinkPreview({ url, children, index }) {
  const imageContainerRef = useRef(null)
  const titleRef = useRef(null)
  const descriptionRef = useRef(null)

  const [containerHeight, setContainerHeight] = useState(
    imageContainerRef.current?.clientHeight || DEFAULT_MAX_HEIGHT
  )

  // maximum sizes for images
  const [textMaxHeight, setTextMaxHeight] = useState(containerHeight) //maximum height without padding

  const [titleMaxLines, setTitleMaxLines] = useState(0)
  const [descriptionMaxLines, setDescriptionMaxLines] = useState(0)

  const [isError, setIsError] = useState(false)
  const [favicon, setFavicon] = useState(getFavicon(url))
  const [openGraphDataIsLoading, setOpenGraphDataIsLoading] = useState(true)
  const [openGraphData, setOpenGraphData] = useState({})

  const {
    image,
    description,
    title,
    'video:url': videoUrl,
    'video:type': videoType,
  } = openGraphData

  const setTextSize = useCallback(() => {
    const currentContainerHeight =
      imageContainerRef.current?.clientHeight || DEFAULT_MAX_HEIGHT
    setContainerHeight(currentContainerHeight)

    const currentDescriptionMaxHeight = currentContainerHeight
    const currentTitleMaxHeight = currentContainerHeight

    setTextMaxHeight(currentDescriptionMaxHeight)

    setTitleMaxLines(
      Math.floor(
        getAvailableHeight(titleRef.current, currentTitleMaxHeight) /
          LINE_HEIGHT
      )
    )

    setTimeout(() => {
      const availableDescriptionHeight = getAvailableHeight(
        descriptionRef.current,
        currentDescriptionMaxHeight
      )
      setDescriptionMaxLines(
        Math.floor(availableDescriptionHeight / LINE_HEIGHT)
      )
    }, 100)
  }, [imageContainerRef, descriptionRef, titleRef])

  useEffect(() => {
    let isMounted = true // Cleanup-Flag

    if (isMounted) {
      setOpenGraphDataIsLoading(true)
      setTextSize()
      setFavicon(getFavicon(url))

      fetchOpenGraphData({ url, allMeta: true })
        .then(({ data }) => setOpenGraphData(data))
        .catch(() => setIsError(true))
        .finally(() => {
          setTextSize()
          setOpenGraphDataIsLoading(false)
        })
    }

    return () => {
      isMounted = false // Markiere die Komponente als unmontiert
    }
  }, [setTextSize, url])

  useEffect(() => {
    const handleWindowResize = debounce(setTextSize, 200)

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  const hasDescription = description && descriptionMaxLines !== 0

  return (
    <Link url={url} index={index} isInPreview={true}>
      <span className={styles.linkPreview}>
        <span
          ref={imageContainerRef}
          className={styles.imageContainer}
          contentEditable={false}
        >
          {openGraphDataIsLoading ? (
            <span className="flex h-full w-full items-center justify-center bg-gray-200">
              <LoadingSpinner />
            </span>
          ) : VIDEO_FEATURE && videoType === 'text/html' && videoUrl ? (
            <iframe
              width="100%"
              height="100%"
              src={videoUrl}
              title={title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              style={{ border: 'none' }}
            ></iframe>
          ) : image ? (
            <span className="block h-full w-full bg-black">
              <img
                className="h-full w-full object-contain"
                src={image}
                alt={title}
              />
            </span>
          ) : (
            <ImagePlaceholder className="h-full w-full" />
          )}

          <span className="absolute bottom-0 right-0 p-2">
            <ExternalLink width={24} className="text-gray-300" />
          </span>
        </span>

        <span
          className={styles.textContainer}
          style={{ maxHeight: textMaxHeight }}
        >
          <span className="flex items-center gap-2 mb-2">
            <img className={styles.icon} src={favicon} alt="favicon" />
            <span className={styles.link}>
              <Renderer content={children} startIndex={index} />
            </span>
          </span>
          {!isError ? (
            <>
              <span
                ref={titleRef}
                contentEditable={false}
                className={`${styles.title} ${
                  hasDescription
                    ? ''
                    : `${styles.lineClamped} line-clamp-${titleMaxLines}`
                }`}
              >
                {title}
              </span>
              {description && (
                <span
                  ref={descriptionRef}
                  contentEditable={false}
                  className={`${styles.description} line-clamp-${descriptionMaxLines}`}
                >
                  {description}
                </span>
              )}
            </>
          ) : (
            <span
              ref={descriptionRef}
              contentEditable={false}
              className={styles.erroredTitle}
            >
              Vorschau konnte nicht geladen werden
            </span>
          )}
        </span>
      </span>
    </Link>
  )
}
