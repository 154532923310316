import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const StyledSpinner = styled.span`
  ${tw`flex`}

  span {
    ${tw`w-4 h-4 bg-gray-500 rounded-full mr-1 block`}
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;

    &:last-child {
      ${tw`mr-0`}
    }
  }

  & .bounce1 {
    animation-delay: -0.64s;
  }

  & .bounce2 {
    animation-delay: -0.48s;
  }

  & .bounce3 {
    animation-delay: -0.32s;
  }

  & .bounce4 {
    animation-delay: -0.16s;
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`

const LoadingSpinner = ({ className }) => {
  return (
    <StyledSpinner className={className}>
      <span className="bounce1"></span>
      <span className="bounce2"></span>
      <span className="bounce3"></span>
      <span className="bounce4"></span>
      <span className="bounce5"></span>
    </StyledSpinner>
  )
}

export default LoadingSpinner
