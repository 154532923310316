import React from 'react'

import Logo from 'assets/howoge_logo.png'
import LoadingLayer from 'components/LoadingLayer'
import ScrollWrapper from 'components/ScrollWrapper'
import DashboardGrid from 'components/Dashboard/DashboardGrid'

import { useMe } from 'data/me'
import Container from 'components/Container'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import tw from 'twin.macro'
import Icon from 'components/IconLoader'

const APP_VERSION = process.env.REACT_APP_VERSION

const StyledLink = styled(Link)`
  ${tw`flex items-center px-4 py-2 bg-gray-600 hover:bg-gray-500 active:bg-gray-700 text-gray-100 rounded`}

  & svg {
    ${tw`mr-1 w-5 h-5`}
  }
`

const HomeScene = () => {
  const { me, isLoading: isLoadingMe, isError: isErrorMe } = useMe()

  if (isLoadingMe || isErrorMe)
    return <LoadingLayer isError={isErrorMe} isLoading={isLoadingMe} />

  return (
    <ScrollWrapper>
      <Container className="pt-0">
        <div className="home">
          <div className="flex justify-end w-full px-3 pb-10">
            <img src={Logo} alt="HOWOGE Logo" style={{ width: '161px' }} />{' '}
          </div>
          <div className="flex flex-row items-start w-full px-3">
            <div className="flex flex-col w-2/3 items-start">
              <h1 className="text-2xl font-bold pb-0 m-0">
                Hallo {me.formattedName}
              </h1>
            </div>
            <div className="flex flex-col w-1/3 items-end">
              <StyledLink to="/categories">
                <Icon name="grid" />
                <span>Zu den Prozess-Kategorien</span>
              </StyledLink>
            </div>
          </div>
          <DashboardGrid />
          <div className="flex justify-start w-full px-3">
            <span className="text-gray-500">Version {APP_VERSION}</span>
          </div>
        </div>
      </Container>
    </ScrollWrapper>
  )
}

export default HomeScene
