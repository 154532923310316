import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import axios from 'axios'

import { ExternalLink, Info } from 'react-feather'
import LoadingSpinner from 'components/LoadingSpinner'

const StyledWrapper = styled.div`
  ${tw`flex items-center justify-between flex-col lg:flex-row text-blue-500 p-4 my-8 border border-blue-200 rounded`}
`

const StyledProcessLink = styled(NavLink)`
  ${tw`py-2 px-4 bg-blue-600 text-white hover:no-underline rounded hover:bg-blue-700 active:bg-blue-800 transition-all duration-100 font-semibold`}
`

const getRelationalEntities = async (step, getModifiedProperties) => {
  const relations = {}

  if (!step) return relations

  const isStepInEditMode = ['draft', 'review', 'pending'].includes(step?.state)

  if (isStepInEditMode && getModifiedProperties) {
    const {
      data: { data: linkedStep },
    } = step.modified_linked_step_id
      ? await axios.get(`/steps/${step.modified_linked_step_id}`)
      : { data: { data: null } }
    const {
      data: { data: linkedProcess },
    } = step.modified_linked_process_id
      ? await axios.get(`/processes/${step.modified_linked_process_id}`)
      : { data: { data: null } }
    const {
      data: { data: linkedCategory },
    } = step.modified_linked_category_id
      ? await axios.get(`/categories/${step.modified_linked_category_id}`)
      : { data: { data: null } }

    relations.linkedStep = linkedStep
    relations.linkedProcess = linkedProcess
    relations.linkedCategory = linkedCategory
  } else {
    relations.linkedStep = step.linked_step
    relations.linkedProcess = step.linked_process
    relations.linkedCategory = step.linked_category
  }

  return relations
}

export const LinkedReference = ({ step, showEditState }) => {
  const [relations, setRelations] = useState({})

  useEffect(() => {
    let isMounted = true

    getRelationalEntities(step, showEditState).then(rels => {
      if (isMounted) setRelations(rels)
    })

    return () => {
      isMounted = false
    }
  }, [step, showEditState])

  if (!step) return

  const {
    modified_linked_step_id,
    modified_linked_process_id,
    modified_linked_category_id,
    linked_step_id,
    linked_process_id,
    linked_category_id,
  } = step

  const { linkedStep, linkedProcess, linkedCategory } = relations

  if (
    (showEditState &&
      (modified_linked_step_id ||
        modified_linked_process_id ||
        modified_linked_category_id)) ||
    linked_step_id ||
    linked_process_id ||
    linked_category_id
  ) {
    if (linkedStep || linkedProcess || linkedCategory) {
      return (
        <StyledWrapper>
          <div className="mb-2 mr-4 flex items-center lg:mb-0">
            <Info width={20} className="mr-2" />
            {_getMessage(relations)}
          </div>
          <StyledProcessLink
            className="flex items-center"
            to={_getPath(relations)}
          >
            <ExternalLink width={16} className="mr-2 text-blue-200" />
            {_getName(relations)}
          </StyledProcessLink>
        </StyledWrapper>
      )
    } else {
      return <LoadingSpinner className="justify-center pb-0.5 mb-10 mt-20" />
    }
  }

  // Render nothing if there's no reference of any type
  return null
}

const _getMessage = ({ linkedStep, linkedProcess, linkedCategory }) => {
  if (linkedStep) {
    return 'Dieser Schritt verweist auf einen anderen Schritt.'
  }

  if (linkedProcess) {
    return 'Dieser Schritt verweist auf einen anderen Prozess.'
  }

  if (linkedCategory) {
    return 'Dieser Schritt verweist auf eine andere Kategorie.'
  }
}

const _getName = ({ linkedStep, linkedProcess, linkedCategory }) => {
  if (linkedStep) return linkedStep.title
  if (linkedProcess) return linkedProcess.name
  if (linkedCategory) return linkedCategory.name
}

const _getPath = ({ linkedStep, linkedProcess, linkedCategory }) => {
  let path = ''

  if (linkedCategory) {
    path = path + `/categories/${linkedCategory.id}`
  }

  if (linkedProcess) {
    path = path + `/processes/${linkedProcess.id}`
  }

  if (linkedStep) {
    path = path + `/${linkedStep.id}`
  }

  return path
}
