import React, { useEffect, useState } from 'react'
import { Link, NavLink, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import Icon from 'components/IconLoader'
import UnpublishedIcon from 'components/UnpublishedIcon'
import isEmpty from 'lodash/isEmpty'

const TOC = styled.div`
  a {
    ${tw`flex items-center px-3 py-2 rounded mb-px transition-all duration-100 ease-out`}

    &:hover {
      ${tw`bg-gray-200`}
    }

    &.active {
      ${tw`bg-gray-200`}
    }

    &:active {
      ${tw`bg-gray-300`}
    }

    & svg {
      ${tw`w-4 h-4`}
    }

    & span {
      ${tw`truncate`}
    }
  }
`

const StyledUnsortedList = styled.ul`
  ${tw`list-none px-3 pb-3`}
`

const StyledStep = styled.li`
  &:not(:last-child) {
    ${tw`mb-1`}
  }
`

const StepHolder = ({ className, steps, baseUrl, currentStep }) => {
  return (
    <StyledUnsortedList className={className}>
      {steps &&
        steps.map(step => {
          return (
            <Step
              key={step.id}
              step={step}
              baseUrl={baseUrl}
              currentStep={currentStep}
            />
          )
        })}
    </StyledUnsortedList>
  )
}

const Step = ({ step, baseUrl, currentStep, className }) => (
  <StyledStep className={className}>
    <NavLink className="justify-between" to={`${baseUrl}/${step.id}`}>
      <span className="flex items-center w-full truncate">
        <UnpublishedIcon entity={step} />
        <span>{step.title}</span>
      </span>
      {step.children_sorted.length > 0 && step !== currentStep && (
        <div className="ml-2">
          <Icon name="chevron-right" />
        </div>
      )}
    </NavLink>
  </StyledStep>
)

const ProcessTOC = ({ className, process, baseUrl, currentStep = null }) => {
  const processUrlMatch = useRouteMatch(baseUrl).isExact
  const [parentStep, setParentStep] = useState()
  const [parentParent, setParentParent] = useState()
  const [currentStepLevel, setCurrentStepLevel] = useState([])
  const [isRootLevel, setIsRootLevel] = useState(true)

  useEffect(() => {
    if (currentStep && process && process.steps_flat) {
      setParentStep(
        process.steps_flat.find(step => step.id === currentStep.parent_id)
      )
    } else {
      setParentStep(null)
    }
  }, [currentStep, process])

  useEffect(() => {
    if (parentStep && process && process.steps_flat) {
      setParentParent(
        process.steps_flat.find(step => step.id === parentStep.parent_id)
      )
    } else {
      setParentParent(null)
    }
  }, [parentStep, process])

  useEffect(() => {
    if (currentStep && currentStep.children_sorted.length !== 0) {
      setCurrentStepLevel(currentStep.children_sorted)
    } else if (
      currentStep &&
      currentStep.children_sorted.length === 0 &&
      currentStep.parent_id !== null &&
      parentStep
    ) {
      setCurrentStepLevel(parentStep.children_sorted)
    } else {
      setCurrentStepLevel(
        process && process.steps && !isEmpty(process.steps) && process.steps
      )
    }
  }, [parentStep, currentStep, process])

  useEffect(() => {
    if (currentStep) {
      if (currentStep.parent_id === null && currentStep.children_sorted) {
        setIsRootLevel(currentStep.children_sorted.length === 0)
      } else {
        setIsRootLevel(false)
      }
    } else {
      setIsRootLevel(true)
    }
  }, [currentStep])

  return (
    <TOC className={className}>
      <div className="mb-2 pb-2 border-b sticky top-0 p-3 bg-gray-100">
        <Link
          className={`mr-12 font-bold inline-flex items-center ${
            processUrlMatch ? 'active' : ''
          }`}
          to={baseUrl}
        >
          <UnpublishedIcon entity={process} />
          {process.name}
        </Link>
      </div>

      {!isRootLevel &&
        (parentParent &&
        currentStep &&
        currentStep.children_sorted &&
        currentStep.children_sorted.length === 0 ? (
          <div className="mb-2 pb-2 border-b sticky top-0 p-3 bg-gray-100">
            <NavLink className="px-3 py-2" to={`${baseUrl}/${parentParent.id}`}>
              <div className="mr-2">
                <Icon name="chevron-left" />
              </div>
              <span>{parentParent.title}</span>
            </NavLink>
          </div>
        ) : parentStep &&
          currentStep &&
          currentStep.children_sorted &&
          currentStep.children_sorted.length !== 0 ? (
          <div className="mb-2 pb-2 border-b sticky top-0 p-3 bg-gray-100">
            <NavLink className="px-3 py-2" to={`${baseUrl}/${parentStep.id}`}>
              <div className="mr-2">
                <Icon name="chevron-left" />
              </div>
              <span>{parentStep.title}</span>
            </NavLink>
          </div>
        ) : (
          <div className="mb-2 pb-2 border-b sticky top-0 p-3 bg-gray-100">
            <Link className="px-3 py-2" to={`${baseUrl}`}>
              <div className="mr-2">
                <Icon name="chevron-left" />
              </div>
              <span>{process.name}</span>
            </Link>
          </div>
        ))}

      {currentStep &&
        currentStep.children_sorted &&
        currentStep.children_sorted.length !== 0 && (
          <div className="mb-2 mx-3">
            <NavLink
              className="justify-between px-3 py-2"
              to={`${baseUrl}/${currentStep.id}`}
            >
              <span className="w-full flex items-center">
                <UnpublishedIcon entity={currentStep} /> {currentStep.title}
              </span>
            </NavLink>
          </div>
        )}
      {parentStep &&
        currentStep &&
        currentStep.children_sorted &&
        currentStep.children_sorted.length === 0 && (
          <div className="mb-2 mx-3">
            <NavLink
              className="justify-between px-3 py-2"
              to={`${baseUrl}/${parentStep.id}`}
            >
              <span className="w-full flex items-center">
                <UnpublishedIcon entity={parentStep} /> {parentStep.title}
              </span>
            </NavLink>
          </div>
        )}

      <StepHolder
        className={!isRootLevel ? 'ml-2 pl-2' : ''}
        steps={currentStepLevel}
        baseUrl={baseUrl}
        currentStep={currentStep}
      />
    </TOC>
  )
}

export default ProcessTOC
