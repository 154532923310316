import axios from 'axios'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'

import { Search } from 'react-feather'

import {
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'

import SearchResultItem from 'components/Search/SearchResultItem'

import Container from 'components/Container'
import ScrollWrapper from 'components/ScrollWrapper'

import useDebounce from 'hooks/use-debounce'

const useQuery = () => queryString.parse(useLocation().search)

const SearchScene = ({ history }) => {
  const queryParams = useQuery()
  const [searchTerm, setSearchTerm] = useState('')
  const [q, setQ] = useState(queryParams.q ?? '')
  const [searchResults, setSearchResults] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [hasResults, setHasResults] = useState(false)

  const debouncedSearchTerm = useDebounce(q, 500)

  useEffect(() => {
    const newQuery = { q: q ?? null }

    history.push({ search: queryString.stringify(newQuery) })
  }, [q, history])

  // Here's where the API call happens
  // We use useEffect since this is an asynchronous action
  useEffect(
    () => {
      async function doSearch(debouncedSearchTerm) {
        await performSearch(debouncedSearchTerm)
      }

      doSearch(debouncedSearchTerm)
    },
    // This is the useEffect input array
    // Our useEffect function will only execute if this value changes ...
    // ... and thanks to our hook it will only change if the original ...
    // value (searchTerm) hasn't changed for more than 500ms.
    [debouncedSearchTerm]
  )

  useEffect(() => {
    setHasResults(
      // prettier-ignore
      !isEmpty(searchResults) && (
        (searchResults.hasOwnProperty('category') && !isEmpty(searchResults.category.data)) ||
        (searchResults.hasOwnProperty('process') && !isEmpty(searchResults.process.data)) ||
        (searchResults.hasOwnProperty('step') && !isEmpty(searchResults.step.data))
      )
    )
  }, [searchResults])

  const performSearch = async query => {
    if (query && query.trim().length !== 0) {
      setIsSearching(true)

      const result = await axios.post(`/search`, {
        term: query,
      })

      setSearchTerm(query)
      setSearchResults(result.data)

      setIsSearching(false)
    } else {
      setIsSearching(false)
      setSearchTerm('')
      setSearchResults(null)
    }
  }

  return (
    <ScrollWrapper>
      <Container>
        <div className="flex-col items-center w-full">
          <h1>Suche</h1>
          <div>
            <form
              className="w-full"
              onSubmit={e => {
                e.preventDefault()

                performSearch(q)
              }}
            >
              <FormControl>
                <InputGroup>
                  <Input
                    autoFocus
                    type="text"
                    boxShadow="inner"
                    id="search"
                    placeholder="Suchbegriff eingeben…"
                    borderColor="gray.300"
                    bg="white"
                    _focus={{ outline: 'none' }}
                    value={q}
                    onChange={e => setQ(e.target.value)}
                  />
                  <InputRightElement
                    children={
                      <IconButton
                        icon={<Search size={20} />}
                        type="submit"
                        color="gray.300"
                        variant="ghost"
                        isLoading={isSearching}
                        _focus={{ outline: 'none' }}
                        _hover={{ bg: 'transparent' }}
                      />
                    }
                  />
                </InputGroup>
              </FormControl>
            </form>
          </div>
          {searchTerm && !isSearching && !hasResults && (
            <div className="my-8">Keine Ergebnisse gefunden.</div>
          )}

          {searchTerm && isSearching && (
            <div className="my-8">Es wird gesucht…</div>
          )}

          {!isSearching && hasResults && searchResults && (
            <div>
              {/* Categories */}
              {searchResults.category &&
                searchResults.category.data &&
                searchResults.category.data.length > 0 && (
                  <div className="my-8">
                    <h3 className="truncate">Kategorien</h3>
                    {searchResults.category.data.map((category, index) => (
                      <SearchResultItem
                        key={index}
                        item={category}
                        searchTerm={searchTerm}
                      />
                    ))}
                  </div>
                )}
              {/* Processes */}
              {searchResults.process &&
                searchResults.process.data &&
                searchResults.process.data.length > 0 && (
                  <div className="my-8">
                    <h3 className="truncate">Prozesse</h3>
                    {searchResults.process.data.map((process, index) => (
                      <SearchResultItem
                        key={index}
                        item={process}
                        searchTerm={searchTerm}
                      />
                    ))}
                  </div>
                )}
              {/* Steps and Contents*/}
              {searchResults.step &&
                searchResults.step.data &&
                searchResults.step.data.length > 0 && (
                  <div className="my-8">
                    <h3 className="truncate">Prozessschritte und Inhalte</h3>
                    {searchResults.step.data.map((step, index) => (
                      <SearchResultItem
                        key={index}
                        item={step}
                        searchTerm={searchTerm}
                      />
                    ))}
                  </div>
                )}
            </div>
          )}
        </div>
      </Container>
    </ScrollWrapper>
  )
}

export default SearchScene
