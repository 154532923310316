import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'

import 'tippy.js/dist/tippy.css' // optional

import { useUnreadNotificationsCount } from 'data/notifications'

import PrivateRoute from 'components/Auth/PrivateRoute'

import { AuthContext } from 'context/auth'
import { BackButtonContext } from 'context/back-button'
import { NotificationContext } from 'context/notification'
import AuthService from 'services/AuthService'

import AppWrapper from 'AppWrapper'
import LoginView from 'scenes/Auth/Login'

const App = () => {
  const [user, setUser] = useState()
  const [backTo, setBackTo] = useState(null)

  // Polling for notifications
  const {
    count: unreadNotificationsCount,
    mutate: mutateUnreadNotifications,
  } = useUnreadNotificationsCount()

  useEffect(() => {
    const subscription = AuthService.user.subscribe(setUser)

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  const logout = async () => {
    try {
      await AuthService.logout()

      window.location.reload()
    } catch (e) {
      console.error('Logout failed.', e)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user: user,
        login: AuthService.login,
        logout: logout,
        checkLogin: AuthService.checkLogin,
      }}
    >
      <BackButtonContext.Provider
        value={{
          backTo,
          setBackTo,
        }}
      >
        <NotificationContext.Provider
          value={{
            count: unreadNotificationsCount,
            refreshCount: mutateUnreadNotifications,
          }}
        >
          <Switch>
            <Route exact path="/login" component={LoginView} />
            <PrivateRoute path="/" component={AppWrapper} />
          </Switch>
        </NotificationContext.Provider>
      </BackButtonContext.Provider>
    </AuthContext.Provider>
  )
}

export default App
