import Button from 'components/Button'
import LoadingSpinner from 'components/LoadingSpinner'
import React from 'react'
import styles from './dashboard.module.css'
import theme from 'theme'

function BoxHeader(props) {
  return (
    <header
      className={styles.header}
      style={{ backgroundColor: theme.default.colors.primary }}
    >
      {props.icon ? <div className={styles.icon}>{props?.icon}</div> : ''}
      {props.title ? <span className={styles.title}>{props.title}</span> : ''}
      {props.children}
    </header>
  )
}

function BoxFooter(props) {
  return <footer className={styles.footer}>{props.children}</footer>
}

function BoxContent(props) {
  if (props.hasNoContent && !props.isLoading) {
    return (
      <div className={styles.content}>
        {props.noContentText || 'Kein Inhalt gefunden'}
      </div>
    )
  }

  if (props.isLoading)
    return (
      <div className={styles.loading}>
        <LoadingSpinner />
      </div>
    )
  return <div className={styles.content}>{props.children}</div>
}

function FooterButton(props) {
  return (
    <Button
      className={styles.footerButton}
      ghost
      label={props.children}
      isLoading={props.isLoading}
    >
      {props.children}
    </Button>
  )
}

function Box(props) {
  const childrenWithProps = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isLoading: props?.isLoading || false })
    }
    return child
  })

  return <div className={styles.card}>{childrenWithProps}</div>
}

Box.Header = BoxHeader
Box.Content = BoxContent
Box.Footer = BoxFooter
BoxFooter.Button = FooterButton

export default Box
