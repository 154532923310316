import Tippy from '@tippyjs/react'
import React from 'react'
import ProgressiveImage from 'react-progressive-image'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useMedia } from 'data/media'
import theme from 'theme'
import ProcessFrame from 'components/Process/ProcessFrame'

const { REACT_APP_API_URL: API_URL } = process.env

export const StyledImageWrapper = styled.div`
  ${tw`mb-2 flex flex-col items-center`}
`

const StyledContentImage = styled.div`
  ${tw`relative inline-block border border-gray-200`}
`

const StyledImageItemWrapper = styled.div`
  ${tw`absolute inset-0`}
`

const StyledTooltip = styled.button`
  background: ${theme.default.colors.primary};
  ${tw`absolute w-8 h-8 rounded-full flex items-center text-sm flex-col justify-center text-white font-semibold border-2 border-white shadow focus:outline-none`}
`

const StyledCaption = styled.p`
  ${tw`mt-2 mb-0 text-gray-600 text-center`}
`

const Image = ({ block }) => {
  const { data } = block
  const { media_id, tooltips, frames, caption } = data
  const { media, isLoading: isMediaLoading, isError: isMediaError } = useMedia(
    media_id
  )

  if (!block) return

  if (isMediaLoading || isMediaError) return null
  // if (isMediaLoading || isMediaError)
  //   return <LoadingLayer isError={isMediaError} isLoading={isMediaLoading} />

  // Return if is no media
  if (!media || !media?.image_formats) return null

  // Find tiny format and save as object in tinyImage
  let tinyImage = media.image_formats?.find(format => format.type === 'tiny')
  // Fallback for tinyImage
  tinyImage = tinyImage ? tinyImage : media

  // Find large format and save as object in largeImage
  let largeImage = media.image_formats.find(format => format.type === 'large')
  // Fallback for largeImage
  largeImage = largeImage ? largeImage : media

  return (
    <StyledImageWrapper>
      <StyledContentImage>
        <ProgressiveImage
          src={`${API_URL}${largeImage.media_url}`}
          placeholder={`${API_URL}${tinyImage.media_url}`}
        >
          {(src, loading) => (
            <img
              className="max-w-full m-auto transition-all duration-200"
              width={media.width}
              height={media.height}
              style={{
                filter: loading ? 'blur(10px)' : 'blur(0)',
                opacity: loading ? 0.5 : 1,
              }}
              src={src}
              alt="Bild"
            />
          )}
        </ProgressiveImage>
        <StyledImageItemWrapper>
          {frames &&
            frames.map(frame => <ProcessFrame key={frame.id} frame={frame} />)}

          {tooltips &&
            tooltips.map((tooltip, index) => {
              return (
                <Tippy
                  key={index}
                  trigger="click"
                  zIndex="10"
                  appendTo="parent"
                  content={tooltip.content}
                >
                  <StyledTooltip
                    style={{
                      top: `calc(${tooltip.y}%)`,
                      left: `calc(${tooltip.x}%)`,
                    }}
                  >
                    {tooltip.title}
                  </StyledTooltip>
                </Tippy>
              )
            })}
        </StyledImageItemWrapper>
      </StyledContentImage>
      {caption && <StyledCaption>{caption}</StyledCaption>}
    </StyledImageWrapper>
  )
}

export default Image
