import useSWR from 'swr'

export const useMedia = id => {
  const { data, mutate, error } = useSWR(id ? `/media/${id}` : null)

  return {
    media: data && data.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}
