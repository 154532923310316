import React from 'react'

import Renderer from '../Renderer'
import LinkPreview from './LinkPreview'
import styles from './LinkPreview.module.css'

const Link = ({
  url,
  children,
  data,
  className = '',
  index = 0,
  isInPreview = false,
}) => {
  const isValidUrl = url && url.startsWith('http')
  const isPreview = data?.isPreview && isValidUrl

  const linkClasses =
    `${className} ` +
    (isInPreview ? styles.linkWithPreview : 'underline text-blue-500')
  const target = { target: '_blank', rel: 'noreferrer' }

  return isPreview ? (
    <LinkPreview url={url} index={index}>
      {children}
    </LinkPreview>
  ) : (
    <a href={url} {...target} className={linkClasses}>
      {isInPreview ? (
        children
      ) : (
        <Renderer content={children} startIndex={index} />
      )}
    </a>
  )
}

export default Link
