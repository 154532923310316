import { IconButton } from '@chakra-ui/react'
import React, { useState } from 'react'

import { setBookmark, unsetBookmark } from 'data/bookmarks'

import Icon from 'components/IconLoader'
import { useCategory } from 'data/categories'
import { useProcess } from 'data/processes'
import { useStep } from 'data/steps'

const BookmarkButton = ({
  className,
  item,
  isRound,
  variant = 'ghost',
  size,
  iconSize = '6',
  ...rest
}) => {
  const [isSetting, setIsSetting] = useState(false)

  //EKELHAFTIG
  const currentCategoryId =
    item.__type === 'Step'
      ? item.category_id
      : item.__type === 'Process'
      ? item.category.id
      : item.__type === 'Category'
      ? item.id
      : null

  const currentProcessId =
    item.__type === 'Step'
      ? item.process_id
      : item.__type === 'Process'
      ? item.id
      : item.__type === 'Category'
      ? null
      : null

  const currentStepId =
    item.__type === 'Step'
      ? item.id
      : item.__type === 'Process'
      ? null
      : item.__type === 'Category'
      ? null
      : null

  const { mutate: mutateCategory } = useCategory(currentCategoryId)
  const { mutate: mutateProcess } = useProcess(currentProcessId)
  const { mutate: mutateStep } = useStep(currentStepId)

  const toggleBookmark = async () => {
    setIsSetting(true)
    if (!item.__bookmark) {
      console.log(`Setting bookmark ${item.__type} ${item.id}...`)
      await setBookmark(item.__type, item.id)
    } else {
      console.log(`Unsetting bookmark ${item.__bookmark.id}...`)
      await unsetBookmark(item.__bookmark.id)
    }

    console.log(`Mutating ${item.__type}`)
    if (mutateStep) await mutateStep()
    if (mutateProcess) await mutateProcess()
    if (mutateCategory) await mutateCategory()
    console.log('Success')
    setIsSetting(false)
  }

  return (
    <IconButton
      isDisabled={isSetting}
      color="uiColors.gray.500"
      className={className}
      icon={
        item.__bookmark ? (
          <Icon
            className={`w-${iconSize} h-${iconSize} fill-current`}
            name="bookmark"
          />
        ) : (
          <Icon className={`w-${iconSize} h-${iconSize}`} name="bookmark" />
        )
      }
      variant={variant}
      isRound={isRound}
      size={size}
      onClick={e => {
        e.stopPropagation()
        toggleBookmark()
      }}
    />
  )
}
export default BookmarkButton
