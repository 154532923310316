import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import Icon from 'components/IconLoader'

const StyledImagePlaceholder = styled.span`
  height: ${({ styledHeight }) => styledHeight};
  max-height: ${({ styledMaxHeight }) => styledMaxHeight};

  ${tw`min-h-full w-full flex justify-center items-center bg-gray-200 fill-current text-gray-300`}

  & svg {
    ${tw`w-1/4 h-auto`}
  }
`

const ImagePlaceholder = ({ styledMaxHeight, styledHeight, className }) => (
  <StyledImagePlaceholder
    className={className}
    styledHeight={styledHeight}
    styledMaxHeight={styledMaxHeight}
  >
    <Icon name="image" />
  </StyledImagePlaceholder>
)

export default ImagePlaceholder
