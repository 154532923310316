import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useAuth } from 'context/auth'
import AuthService from 'services/AuthService'

import Logo from 'assets/howoge_logo.png'
import Button from 'components/Button'
import Icon from 'components/IconLoader'

const APP_VERSION = process.env.REACT_APP_VERSION
const APP_SP_SSO_URL = process.env.REACT_APP_SP_SSO_URL

const AuthContainer = styled.div`
  ${tw`h-screen px-4 bg-gray-100 overflow-auto`}
  padding-top: 10vh;
  padding-bottom: 10vh;
`

const StyledButton = styled(Button)`
  ${tw`transition-all duration-100`}
  ${tw`flex items-center w-full justify-center px-4 py-2 rounded text-white bg-gray-800 hover:bg-gray-600 active:bg-gray-900 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed`}
  ${tw`active:outline-none focus:outline-none`}
`

const StyledLink = styled.a`
  ${tw`transition-all duration-200`}
  ${tw`flex items-center w-full justify-center px-4 py-2 rounded text-gray-900 bg-white`}
  ${tw`border border-gray-500 shadow hover:shadow-md active:shadow-sm`}
`

const LoginView = ({ history }) => {
  const { state } = useLocation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  // const [submittable, setSubmittable] = useState(false)
  const [hasLoginFailed, setHasLoginFailed] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)

  const { login, checkLogin } = useAuth()

  const currentUser = AuthService.currentUserValue

  useEffect(() => {
    !currentUser && checkLogin()
  }, [checkLogin, email, currentUser, password])

  const referer = state?.from || '/'

  const performLogin = useCallback(
    async e => {
      e.preventDefault()

      try {
        setHasLoginFailed(false)
        await login(email, password)

        history.push(referer)
      } catch (e) {
        setHasLoginFailed(true)
      }
    },
    [email, history, login, password, referer]
  )

  return currentUser ? (
    <Redirect to={referer} />
  ) : (
    <AuthContainer>
      <div className="w-full max-w-md mx-auto">
        <div className="mb-4 flex justify-center">
          <div>
            <img className="block w-36" src={Logo} alt="HOWOGE Brand Logo" />
          </div>
        </div>
        <h1 className="font-semibold text-4xl mb-8 text-gray-900 text-center">
          HOWOGuide
        </h1>
        <div className="rounded bg-white p-12 shadow">
          <div className="flex items-center justify-end">
            <StyledLink
              href={
                `${APP_SP_SSO_URL}&redirect=` +
                `${window.location.origin}${referer.pathname}`
              }
            >
              <Icon className="w-4 h-4 mr-2 fill-current" name="microsoft" />
              <span>Einloggen</span>
            </StyledLink>
          </div>
          <div className="relative mt-8">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm leading-5">
              <button onClick={() => setShowLoginForm(!showLoginForm)}>
                <span className="px-2 bg-white text-gray-400">
                  Mit E-Mail & Passwort einloggen
                </span>
              </button>
            </div>
          </div>
          <form
            onSubmit={performLogin}
            className={`mt-8 ${showLoginForm ? '' : 'hidden'}`}
          >
            <div className="mb-4">
              <input
                placeholder="E-Mail"
                name="email"
                type="email"
                className="w-full form-input"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-8">
              <input
                placeholder="Password"
                name="password"
                type="password"
                className="w-full form-input"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            {hasLoginFailed ? (
              <div className="px-3 py-3 my-6 text-sm text-red-800 bg-red-200 border border-red-700 rounded">
                Der Login ist fehlgeschlagen. Prüfen Sie E-Mail und Passwort.
              </div>
            ) : (
              ''
            )}
            <div className="flex items-center mt-3">
              <StyledButton
                primary
                // disabled={!submittable}
                type="submit"
                label="Einloggen"
              />
            </div>
          </form>
        </div>
        <div className="flex items-center justify-center text-gray-300 mt-6 -mb-6">
          Version {APP_VERSION}
        </div>
      </div>
    </AuthContainer>
  )
}

export default LoginView
