import { createContext, useContext } from 'react'

export const BackButtonContext = createContext({
  backTo: null,
  setBackTo: () => {},
})

export function useBackButton() {
  return useContext(BackButtonContext)
}
