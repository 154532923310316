import useSWR from 'swr'

export const useGraphic = id => {
  const { data, mutate, error } = useSWR(id ? `/graphics/${id}` : null)

  return {
    graphic: data && data.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}
