import React, { useCallback, useEffect, useState } from 'react'
import { NavLink, useParams, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import { Button } from '@chakra-ui/react'

import { ConfirmActionModal } from 'components/ConfirmActionModal'
import Container from 'components/Container'
import { Question } from 'components/Elearning/Question'
import InfoSection from 'components/InfoSection'
import LoadingLayer from 'components/LoadingLayer'
import ScrollWrapper from 'components/ScrollWrapper'

import { useBackButton } from 'context/back-button'
import { examineQuestion, resetExam } from 'data/elearning'
import { useProcess } from 'data/processes'

const StyledShowProcessWrapper = styled.div`
  ${tw`mt-14 flex flex-col bg-white`}
`

const StyledScrollWrapper = styled(ScrollWrapper)`
  ${tw`relative w-full h-full bg-white`}
`

const HowManyQuestionsCorrect = ({ rightQuestionsCount, questionsCount }) => {
  return (
    <div className="flex items-center flex-col justify-center mb-8 text-lg text-center font-semibold italic text-gray-500">
      {rightQuestionsCount === questionsCount ? (
        <div>
          Herzlichen Glückwunsch...
          <br />
          <span className="mr-2" role="img" aria-label="Yepeyyy...">
            🎉
          </span>
          Sie haben alle Fragen richtig beantwortet!
          <span className="ml-2" role="img" aria-label="Yepeyyy...">
            🎉
          </span>
        </div>
      ) : (
        <div>
          Sie haben{' '}
          <span className="font-semibold">
            {rightQuestionsCount} von {questionsCount}
          </span>{' '}
          Fragen richtig beantwortet.
        </div>
      )}
    </div>
  )
}

const ShowQuestionsScene = () => {
  const { cid, pid } = useParams()
  const { setBackTo } = useBackButton()
  const [isResetting, setIsResetting] = useState(false)
  const [showResetConfirmModal, setShowResetConfirmModal] = useState(false)

  let matchQuestionsRoute = useRouteMatch('*/questions')
  console.log(matchQuestionsRoute)

  useEffect(() => {
    if (cid && pid) {
      setBackTo(`/categories/${cid}/processes/${pid}`)
    }
  }, [cid, pid, setBackTo])

  const openResetConfirmModal = useCallback(() => {
    setShowResetConfirmModal(true)
  }, [])
  const closeResetConfirmModal = useCallback(() => {
    setShowResetConfirmModal(false)
  }, [])

  const performReset = async () => {
    setIsResetting(true)
    await resetExam(process.id)
    await mutateProcess()
    setIsResetting(false)
    closeResetConfirmModal()
  }

  const [noQuestionsLeft, setNoQuestionsLeft] = useState(false)
  const [questionsLeft, setQuestionsLeft] = useState(0)
  const [questionsCount, setQuestionsCount] = useState(0)
  const [rightQuestionsCount, setRightQuestionsCount] = useState(0)

  const allQuestionsRight = questionsCount === rightQuestionsCount
  const progress = (questionsLeft / questionsCount) * 100
  const allQuestionsCorrect = rightQuestionsCount === questionsCount

  const {
    process,
    mutate: mutateProcess,
    isLoading: isProcessLoading,
    isError: isProcessError,
  } = useProcess(pid)

  useEffect(() => {
    if (process) {
      setNoQuestionsLeft(
        process.questions.filter(question => question.is_answerable === true)
          .length === 0
      )
      setQuestionsLeft(process.questions.filter(q => !q.is_answerable).length)
      setQuestionsCount(process.questions.length)
      setRightQuestionsCount(
        process.questions.filter(q => q.is_answered_correct).length
      )
    }
  }, [process])

  const saveQuestion = async (id, givenAnswers) => {
    await examineQuestion(id, givenAnswers)
    await mutateProcess()
  }

  if (isProcessLoading || isProcessError) {
    return (
      <LoadingLayer isError={isProcessError} isLoading={isProcessLoading} />
    )
  }

  return (
    <StyledShowProcessWrapper>
      <InfoSection
        questions
        matchQuestionsRoute
        process={process}
        progress={progress}
        allQuestionsCorrect={allQuestionsCorrect}
      />
      <div className="flex">
        <StyledScrollWrapper>
          <Container>
            <h1>Fragebogen</h1>
            {/* {noQuestionsLeft && !isResetting && ( */}
            <HowManyQuestionsCorrect
              rightQuestionsCount={rightQuestionsCount}
              questionsCount={questionsCount}
            />
            {/* )} */}
            <div>
              {!isResetting &&
                process.questions &&
                process.questions.map(question => (
                  <Question
                    key={question.id}
                    question={question}
                    onSave={saveQuestion}
                    showEvaluation={noQuestionsLeft}
                  />
                ))}
            </div>
            <HowManyQuestionsCorrect
              rightQuestionsCount={rightQuestionsCount}
              questionsCount={questionsCount}
            />
            {noQuestionsLeft && !isResetting && (
              <div className="flex items-center justify-center mb-8">
                <Button
                  onClick={openResetConfirmModal}
                  variant={allQuestionsRight ? 'ghost' : 'solid'}
                  colorScheme={allQuestionsRight ? 'gray' : 'blue'}
                  mr="2"
                >
                  {allQuestionsRight ? 'Zurücksetzen' : 'Das kann ich besser'}
                </Button>
                <Button
                  as={NavLink}
                  to={`/categories/${cid}/processes/${pid}`}
                  variant={allQuestionsRight ? 'solid' : 'outline'}
                  colorScheme={allQuestionsRight ? 'green' : 'gray'}
                >
                  {allQuestionsRight
                    ? 'Zum Prozess'
                    : 'Später erneut versuchen'}
                </Button>
              </div>
            )}
          </Container>
        </StyledScrollWrapper>
      </div>
      <ConfirmActionModal
        title="Fragebogen wiederholen"
        isOpen={showResetConfirmModal}
        explanation={
          <p>
            Achtung! Alle Antworten werden zurückgesetzt. Dieser Vorgang kann
            nicht rückgängig gemacht werden.
          </p>
        }
        isLoading={isResetting}
        onConfirm={performReset}
        onClose={closeResetConfirmModal}
        variant={allQuestionsRight ? 'ghost' : 'solid'}
      />
    </StyledShowProcessWrapper>
  )
}

export default ShowQuestionsScene
