/**
 * Calculates the margin height of an element.
 * @param {HTMLElement} el - The element to calculate the margin height for.
 * @returns {number} The margin height of the element.
 */
export function getMarginHeight(el) {
  return (
    parseInt(window.getComputedStyle(el).marginTop) +
    parseInt(window.getComputedStyle(el).marginBottom)
  )
}
/**
 * Calculates the margin width of an element.
 * @param {HTMLElement} el - The element to calculate the margin width for.
 * @returns {number} The margin width of the element.
 */
export function getMarginWidth(el) {
  return (
    parseInt(window.getComputedStyle(el).marginLeft) +
    parseInt(window.getComputedStyle(el).marginRight)
  )
}
