import React, { useState, useEffect } from 'react'

import axios from 'axios'

import NewsComponent from './Boxes/News'
import BookmarksComponent from './Boxes/Bookmarks'
import NotificationsComponent from './Boxes/Notifications'
import SupportComponent from './Boxes/Support'
import LoadingSpinner from 'components/LoadingSpinner'

const components = {
  News: NewsComponent,
  Bookmarks: BookmarksComponent,
  Notifications: NotificationsComponent,
  Support: SupportComponent,
}

const Box = (cname, props) => {
  if (!cname) {
    console.error('cname', cname)
    return
  }

  let DynamicComponent = components[`${cname}`]
  return <DynamicComponent {...props} />
}

const Component = () => {
  // const [dashboards, setDashboards] = useState([])
  const [isLoadingDashboards, setIsLoadingDashboards] = useState([])
  const [activeDashboard, setActiveDashboard] = useState(null)
  const [dashboardBoxes, setDashboardBoxes] = useState([])

  function fetchDashboards() {
    return axios.get(`/dashboards`, {
      params: {
        where: { type: 'webapp' },
      },
    })
  }

  function fetchDashboardBoxes(dashboad_id) {
    return axios.get(`/dashboard-boxes`, {
      params: {
        where: { dashboard_id: dashboad_id },
      },
    })
  }

  useEffect(() => {
    setIsLoadingDashboards(true)

    fetchDashboards()
      .then(({ data: response }) => {
        const dashboards = response?.data
        const webappDashboard = dashboards?.find(
          dashboard => dashboard.type === 'webapp'
        )

        // setDashboards(dashboards)
        setActiveDashboard(webappDashboard)
        setIsLoadingDashboards(false)
      })
      .catch(() => {
        setIsLoadingDashboards(false)
      })
  }, [])

  // Style object for the grid container
  const [gridStyle, setGridStyle] = useState({
    display: 'grid',
    gridTemplateColumns: `repeat(3, 1fr)`,
    gridAutoRows: 'minmax(384px, 400px)',
    gap: '20px',
    padding: '16px',
  })

  useEffect(() => {
    if (activeDashboard) {
      fetchDashboardBoxes(activeDashboard?.id).then(({ data: response }) => {
        setDashboardBoxes(response.data)
      })
      const cols = activeDashboard?.columns || 3

      setGridStyle(gridStyle => ({
        ...gridStyle,
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
      }))
    }
  }, [activeDashboard])

  // Style object for the cells
  const cellStyle = {
    boxShadow: 'inset 0 0 1rem rgba(226,232,240,0.93)',
    position: 'relative',
    borderRadius: '4px',
    minWidth: '50%',
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  return (
    <div>
      {isLoadingDashboards && (
        <div className="loading">
          <LoadingSpinner />
        </div>
      )}
      <div
        style={{
          ...gridStyle,
          position: 'relative',
          pointerEvents: 'none',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
        }}
      >
        {dashboardBoxes
          ? dashboardBoxes.map((box, index) => (
              <div
                key={`l-${index}`}
                style={{
                  ...cellStyle,
                  pointerEvents: 'auto',
                  backgroundColor: 'white',
                  gridColumn: `${box.left + 1}`,
                  gridRow: `${box.top + 1} / ${box.top + 1 + box.height}`,
                }}
              >
                {Box(box.type, { box })}
              </div>
            ))
          : ''}
      </div>
    </div>
  )
}

export default Component
