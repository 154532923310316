import DashboardBox from 'components/Dashboard/DashboardBox'
import { useCategory } from 'data/categories'
import React, { useEffect, useState } from 'react'
import { ArrowRight, ChevronRight, Star } from 'react-feather'
import { Link } from 'react-router-dom'
import theme from 'theme'

import styles from './index.module.css'

const getNewsTitle = news => {
  if (!news) return
  const title = news.name
  return title
}

const getCategoryUrl = category => {
  if (!category) return
  const url = `/categories/${category?.id}`
  return url
}

const getNewsUrl = news => {
  if (!news) return
  const url = `${getCategoryUrl(news.category)}/processes/${news.id}`
  return url
}

const Component = ({ box = null }) => {
  const [options, setOptions] = useState({
    max: 3,
    title: 'Neuigkeiten',
  })

  const boxOptionsMax = box.options?.max
  const boxOptionsTitle = box.options?.title

  useEffect(() => {
    if (box.options) {
      setOptions(options => ({
        ...options,
        ...JSON.parse(box?.options || {}),
      }))
    }
  }, [box.options, boxOptionsMax, boxOptionsTitle])

  const [processes, setProcesses] = useState([])
  const { category, isLoading } = useCategory(options.category)

  const [categoryUrl, setCategoryUrl] = useState(getCategoryUrl(category))
  useEffect(() => {
    setCategoryUrl(getCategoryUrl(category))
  }, [category])

  useEffect(() => {
    if (options.category && category?.processes) {
      const sortedArray = category.processes
        .filter(x => x.published_at)
        .sort((a, b) => new Date(b.published_at) - new Date(a.published_at))
      const slicedArray = sortedArray.slice(0, parseInt(options.max))
      setProcesses(slicedArray)
    }
  }, [category, options.category, options.max])

  return (
    <DashboardBox isLoading={isLoading}>
      <DashboardBox.Header
        icon={<Star size={24} />}
        title={options?.title || 'Neuerungen im Howoguide'}
      />
      <DashboardBox.Content
        noContentText="Es sind keine Neuigkeiten vorhanden."
        hasNoContent={processes?.length < 1 || !processes}
      >
        {processes &&
          processes.map((new_inf, index) => {
            return (
              <div
                className={styles.wrapper}
                key={`dashboard-process-${index}`}
              >
                <Star
                  className={`${styles.icon} icon-fill icon-stroke`}
                  style={{ color: theme.default.colors.primary }}
                  size={27}
                />
                <Link className={styles.link} to={getNewsUrl(new_inf)}>
                  <div className={styles.textWrapper}>
                    <div className={styles.title}>{getNewsTitle(new_inf)}</div>
                    {index === 0 && new_inf.description && (
                      <div className={`${styles.path} m-0 p-0`}>
                        {new_inf.description}
                      </div>
                    )}
                  </div>
                  <ChevronRight size={24} className={styles.iconGray} />
                </Link>
              </div>
            )
          })}
      </DashboardBox.Content>
      <DashboardBox.Footer>
        {categoryUrl && (
          <Link className={styles.link} to={categoryUrl}>
            <DashboardBox.Footer.Button>
              Alle anzeigen
              <ArrowRight className="ml-2" size={20} stroke="#64748B" />
            </DashboardBox.Footer.Button>
          </Link>
        )}
      </DashboardBox.Footer>
    </DashboardBox>
  )
}

export default Component
