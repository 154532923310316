import { createContext, useContext } from 'react'

export const NotificationContext = createContext({
  notifications: [],
  count: 0,
  refreshCount: () => {},
})

export function useNotifications() {
  return useContext(NotificationContext)
}
