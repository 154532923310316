import useSWR from 'swr'

export const useVideos = (page, items = 10) => {
  const { data, error, mutate } = useSWR(page  ? `/videos?page=${page}&items=${items}` : null)

  return {
    videos: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}

export const useVideo = id => {
  const { data, error, mutate } = useSWR(id ? `/videos/${id}` : null)

  return {
    video: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}

export const useCategoryVideos = (categoryId, index) => {
  const { data, error, mutate } = useSWR(
    categoryId && index ? `/videos/category/${categoryId}?page=${index}` : null
  )

  return {
    videos: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}
