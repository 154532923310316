import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import { IconButton } from './Button'

const ModalWrapper = styled.div`
  ${tw`fixed z-40 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50`}
`
const ModalLinkWrapper = styled.div`
  ${tw`flex flex-col items-center w-1/2 p-5 bg-gray-200 rounded`}
`
const ModalHeader = styled.div`
  ${tw`flex justify-between w-full mb-4 border-b-2`}
`

const ModalTitle = styled.h3`
  ${tw`text-xl text-gray-500`}
`

const StyledButton = styled(IconButton)`
  ${tw`flex-row-reverse mb-2`}
`

const Modal = ({ toggleModal }) => {
  const dummyClick = e => {
    e.preventDefault()
    alert('Mach Irgendwas')
  }
  return (
    <ModalWrapper>
      <ModalLinkWrapper>
        <ModalHeader>
          <ModalTitle>Aktualisierungen</ModalTitle>
          <IconButton
            icon="x"
            onClick={toggleModal}
            className={'text-gray-500'}
          />
        </ModalHeader>
        <StyledButton
          label="Instandhaltung"
          icon="chevron-right"
          onClick={dummyClick}
        />
        <StyledButton
          label="Neubau"
          icon="chevron-right"
          onClick={dummyClick}
        />
        <StyledButton
          label="Vermietung"
          icon="chevron-right"
          onClick={dummyClick}
        />
      </ModalLinkWrapper>
    </ModalWrapper>
  )
}

export default Modal
