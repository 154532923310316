import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import Renderer from '../Renderer'

const LayoutWrapper = styled.div`
  ${tw`flex w-full gap-8 overflow-x-auto`}

  > *:first-child {
    ${tw`mt-0`}
  }
  > *:last-child {
    &,
    > p:last-child {
      ${tw`mb-0`}
    }
  }
`

const LayoutElement = styled.div`
  > div {
    ${tw`items-start`}
  }
`

const Layout = ({ data, children: columns, index = 0 }) => {
  return (
    <LayoutWrapper>
      <LayoutElement className={`w-${data.first}`}>
        {columns[0].children.map((child, cellIndex) => {
          const key = `${index}-0-column-child-${cellIndex}`
          return <Renderer key={key} content={child} startIndex={key} />
        })}
      </LayoutElement>
      <LayoutElement className={`w-${data.second}`}>
        {columns[1].children.map((child, cellIndex) => {
          const key = `${index}-1-column-child-${cellIndex}`
          return <Renderer key={key} content={child} startIndex={key} />
        })}
      </LayoutElement>
    </LayoutWrapper>
  )
}

export default Layout
