import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { useMedia } from 'data/media'
import React, { useEffect, useState } from 'react'
import Icon from '../../IconLoader'
import { useDate } from 'hooks/use-format-date'
import useFormatSize from 'hooks/use-format-size'
import LoadingSpinner from 'components/LoadingSpinner'

import styles from './Pdf.module.css'
import { Prompt, useHistory } from 'react-router-dom'
import { Maximize2 } from 'react-feather'

const { REACT_APP_API_URL: API_URL } = process.env

const Pdf = ({ block }) => {
  const [pdfSize, setPdfSize] = useState(null)
  const [uploadDate, setUploadDate] = useState(null)
  const [isLoadingIframe, setIsLoadingIframe] = useState(null)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const history = useHistory()

  const media_id = block?.data?.media_id

  const { media, isLoading: isMediaLoading, isError: isMediaError } = useMedia(
    media_id
  )

  useEffect(() => {
    setIsLoadingIframe(true)
    if (media && media.size) {
      setPdfSize(media.size)
    }
    if (media && media.created_at) {
      setUploadDate(media.created_at.toString())
    } else {
      setUploadDate(new Date().toString(), false)
    }

    return () => {}
  }, [media])

  const formattedDate = useDate(uploadDate)
  const formattedSize = useFormatSize(pdfSize)

  if (isMediaLoading || isMediaError) return null

  // Return if is no media
  if (!media) return null

  return (
    <>
      <div className={styles.Pdf}>
        <div
          className={styles.container}
          role="button"
          tabIndex="0"
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onOpen()
            }
          }}
          onClick={onOpen}
        >
          <div className={styles.body}>
            <div className={styles.icon}>
              <Icon name="pdf" />
            </div>

            <div className={styles.content}>
              <span className={styles.title}>{media.name}</span>

              {pdfSize && uploadDate && (
                <div className={styles.meta}>
                  {formattedDate} | {formattedSize}
                </div>
              )}
            </div>
          </div>

          <div className={styles.button}>
            <span>Öffnen</span>
            <Maximize2 className="ml-2" />
          </div>
        </div>

        {block.data.preview && (
          <div className={styles.loading}>
            {isLoadingIframe && (
              <div
                className={styles.loadingContainer}
                style={{ height: '500px' }}
              >
                <LoadingSpinner />
              </div>
            )}
            <iframe
              width="100%"
              height={500}
              title={`${media.name}`}
              src={`${API_URL}${media.image_url}`}
              alt=""
              className={`${isLoadingIframe ? 'hidden' : ''}`}
              onLoad={() => setIsLoadingIframe(false)}
            />
          </div>
        )}
      </div>
      <Modal onClose={onClose} size={'full'} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent style={{ margin: '0', borderRadius: '0' }}>
          <ModalHeader style={{ padding: '0' }}>
            <div className={styles.modalHeader}>
              <div className={styles.modalHeaderContainer}>
                <div className={styles.modalIcon}>
                  <Icon name="pdf" className={styles.svg} />
                </div>

                <div className="flex w-full flex-col justify-center h-full gap-1 px-4 ">
                  <span className="text-sm font-semibold leading-tight text-slate-800 ">
                    {media.name}
                  </span>
                </div>
                <div className="flex">
                  <Button
                    rightIcon={<Icon name="x" />}
                    variant={'ghost'}
                    fontSize={'sm'}
                    fontWeight={'normal'}
                    onClick={onClose}
                  >
                    Schließen
                  </Button>
                </div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody style={{ padding: '0', display: 'grid' }}>
            {isLoadingIframe && (
              <div className="flex w-full h-full justify-center items-center">
                <LoadingSpinner />
              </div>
            )}
            <iframe
              width="100%"
              height="100%"
              title={`${media.name}`}
              src={`${API_URL}${media.image_url}`}
              alt=""
              className={`${isLoadingIframe ? 'hidden' : ''}`}
              onLoad={() => setIsLoadingIframe(false)}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* TODO: Update react-router-dom to use unstable_useBlocker (best case a stable version if it exists) */}
      {/* FIXME: right now the page jumps to the top */}
      <Prompt
        when={isOpen}
        message={(location, action) => {
          onClose()
          if (action === 'POP') history.go(1) // go back to previous page
        }}
      />
    </>
  )
}

export default Pdf
