import React from 'react'
import Renderer from '../Renderer'

const Paragraph = ({ children, index }) => {
  return (
    <p>
      <Renderer content={children} startIndex={index} />
    </p>
  )
}

export default Paragraph
