import React, { useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import { Button, Checkbox } from '@chakra-ui/react'
import { Check, Circle, X } from 'react-feather'

const uiColors = require('@tailwindcss/ui/colors')

const StyledQuestionWrapper = styled.div`
  ${tw`border-2 rounded-md mb-10 p-8 bg-white`}
  ${({ isAnswerable, isAnsweredCorrect }) =>
    !isAnswerable
      ? isAnsweredCorrect
        ? tw`border-green-100`
        : tw`border-red-100`
      : tw`border-blue-400`}
`

const StyledQuestionText = styled.h4`
  ${tw`mb-2 mr-4 leading-tight`}
  ${({ isAnswerable, isAnsweredCorrect }) =>
    !isAnswerable
      ? isAnsweredCorrect
        ? tw`text-gray-400`
        : tw`text-gray-400`
      : ``}
`

const QuestionText = ({ text, isAnswerable, isAnsweredCorrect }) => {
  return (
    <div className="flex items-start justify-between">
      <StyledQuestionText
        isAnswerable={isAnswerable}
        isAnsweredCorrect={isAnsweredCorrect}
      >
        {text}
      </StyledQuestionText>
      {!isAnswerable ? (
        isAnsweredCorrect ? (
          <Check size={30} className="text-green-500 flex-shrink-0" />
        ) : (
          <X size={30} className="text-red-500 flex-shrink-0" />
        )
      ) : (
        ''
      )}
    </div>
  )
}

const StyledAnswer = styled.label`
  ${tw`mb-1 px-4 flex items-start rounded-lg bg-gray-100 leading-5 hover:bg-gray-200 cursor-pointer`}
`

const Answer = ({
  answer,
  disabled,
  isChecked,
  isCorrect,
  giveAnswer,
  isAnsweredCorrect,
}) => {
  const getAnswerIcon = () => {
    if (isCorrect && isChecked)
      return <Check size="16" color={uiColors.green['500']} />

    if (!isCorrect && isChecked)
      return <X size="16" color={uiColors.red['500']} />

    return <Circle size="16" />
  }

  const isGreen = isCorrect && !isAnsweredCorrect

  return disabled ? (
    <div
      className={`mb-1 px-4 flex items-start rounded-lg ${
        isCorrect && !isAnsweredCorrect && 'bg-green-100 hover:bg-green-100'
      }`}
    >
      <div className="border border-gray-400 p-1 my-4 rounded text-transparent mr-4">
        {getAnswerIcon()}
      </div>
      <span className="py-4 text-gray-500">{answer.text}</span>
    </div>
  ) : (
    <StyledAnswer
      htmlFor={`answer-${answer.id}`}
      isCorrect={isCorrect}
      isGreen={isGreen}
      isDisabled={disabled}
    >
      <Checkbox
        name={`answer-${answer.id}`}
        id={`answer-${answer.id}`}
        my="4"
        mr="4"
        bg="white"
        size="lg"
        borderColor="uiColors.gray.400"
        isChecked={isChecked}
        isDisabled={disabled}
        onChange={() => giveAnswer(answer.id)}
      />
      <span
        className={`w-full text-base my-4 ${isCorrect && 'text-green-700'}`}
      >
        {answer.text}
      </span>
    </StyledAnswer>
  )
}

export const Question = ({ question, onSave, showEvaluation }) => {
  const [givenAnswers, setGivenAnswers] = useState([])
  const [isSaving, setIsSaving] = useState(false)

  const giveAnswer = id => {
    if (givenAnswers.indexOf(id) > -1) {
      const newGivenAnswers = givenAnswers.filter(element => element !== id)
      setGivenAnswers(newGivenAnswers)
    } else {
      const newGivenAnswers = givenAnswers.concat([id])
      setGivenAnswers(newGivenAnswers)
    }
  }

  const {
    is_answerable: isAnswerable,
    is_answered_correct: isAnsweredCorrect,
  } = question

  return (
    <StyledQuestionWrapper
      isAnswerable={isAnswerable}
      isAnsweredCorrect={isAnsweredCorrect}
    >
      <QuestionText
        text={question.text}
        isAnswerable={isAnswerable}
        isAnsweredCorrect={isAnsweredCorrect}
      />
      <>
        <div className="flex flex-col mt-4">
          {question.answers.map(answer => {
            return (
              <Answer
                key={answer.id}
                disabled={!isAnswerable}
                answer={answer}
                giveAnswer={giveAnswer}
                isChecked={question.given_answers.find(
                  givenAnswer => givenAnswer.answer_id === answer.id
                )}
                isCorrect={!isAnswerable && answer.is_correct}
                isAnsweredCorrect={!isAnswerable && isAnsweredCorrect}
              />
            )
          })}
        </div>
        {!showEvaluation && isAnswerable && (
          <div className="flex justify-center mt-4">
            <Button
              onClick={async () => {
                setIsSaving(true)
                await onSave(question.id, givenAnswers)
                setIsSaving(false)
              }}
              isLoading={isSaving}
              isDisabled={!isAnswerable || givenAnswers.length < 1}
              colorScheme={'blue'}
            >
              Beantworten
            </Button>
          </div>
        )}
      </>
    </StyledQuestionWrapper>
  )
}
